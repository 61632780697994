import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosCloseCircle } from "react-icons/io";

const MobileApp = ({ title, icon, children, hideTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [iconRect, setIconRect] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const iconRef = useRef(null);

  const openApp = () => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      setIconRect(rect);
    }
    setIsOpen(true);
  };

  const closeApp = () => {
    // 1) Hide the content immediately
    setShowContent(false);
    // 2) In the next animation frame, trigger the close animation
    requestAnimationFrame(() => {
      setIsOpen(false);
    });
    // Alternatively, you could do:
    // setTimeout(() => setIsOpen(false), 0);
  };

  // Define container variants
  const containerVariants = {
    closed: {
      width: iconRect?.width || 0,
      height: iconRect?.height || 0,
      x: iconRect?.left || 0,
      y: iconRect?.top || 0,
      borderRadius: 12,
      opacity: 1,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
    open: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      borderRadius: 0,
      opacity: 1,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
  };

  return (
    <>
      {/* The home-screen icon */}
      <div
        ref={iconRef}
        onClick={openApp}
        className="flex flex-col items-center space-y-1 cursor-pointer"
      >
        <div className="bg-neutral-800 w-14 h-14 rounded-xl flex items-center justify-center shadow-md">
          <img
            src={icon}
            alt={title}
            className="w-10 h-10 object-cover rounded-md"
          />
        </div>
        {!hideTitle && <span className="text-xs text-center">{title}</span>}
      </div>

      {/* Fullscreen modal & animation */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed top-0 left-0 w-screen h-screen bg-black/90 z-50 flex flex-col"
            variants={containerVariants}
            initial="closed"
            animate="open"
            exit="closed"
            onAnimationStart={(variantName) => {
              // If we're starting the "open" animation, hide content
              if (variantName === "open") {
                setShowContent(false);
              }
            }}
            onAnimationComplete={(variantName) => {
              // If we've just finished the "open" animation, show the content
              if (variantName === "open") {
                setShowContent(true);
              }
            }}
          >
            {/* If showContent is false, only show the large icon in the center */}
            {!showContent ? (
              <div className="flex-grow flex items-center justify-center">
                <img
                  src={icon}
                  alt={title}
                  className="w-16 h-16 object-contain"
                />
              </div>
            ) : (
              <>
                {/* Once fully expanded, show the close button & actual content */}
                <div className="pt-3 pb-3 pl-4 pr-3 flex items-center bg-gray-800/90 backdrop-blur-md border-b border-gray-700 shadow-md w-full shadow-xl">
                  <div className="text-xl font-medium text-gray-200 tracking-wide">
                    {title === "Projects" ? "Personal Projects" : title}
                  </div>

                  <button
                    onClick={closeApp}
                    className="text-gray-400 hover:text-red-500 ml-auto transition-transform duration-200 transform hover:scale-110"
                  >
                    <IoIosCloseCircle size={30} />
                  </button>
                </div>

                <div className="flex-grow overflow-auto bg-neutral-900 text-white">
                  {children}
                </div>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileApp;
