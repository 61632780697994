import React from "react";

const SpaceCadet = () => {
  return (
    <iframe
      src="https://cdn.dos.zone/custom/crafted/space-cadet-pinball/index.html"
      title="Doom"
      style={{ width: "100%", height: "100%" }}
    ></iframe>
  );
};

export default SpaceCadet;
