import React, { useEffect, useState } from "react";
import { TfiMenu } from "react-icons/tfi";

const Taskbar = ({
  setStartMenuActive,
  startMenuActive,
  runningApplications,
  handleFocusApplication,
}) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(new Date());
    }, 1000); // update time every 1 second

    return () => {
      clearInterval(timerID); // cleanup timer on component unmount
    };
  }, []);

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  };

  return (
    <div className="Taskbar">
      {!startMenuActive && (
        <div
          onClick={() => setStartMenuActive(!startMenuActive)}
          style={{
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TfiMenu size={22} />
        </div>
      )}
      {startMenuActive && (
        <div
          onClick={() => setStartMenuActive(!startMenuActive)}
          style={{
            backgroundColor: "white",
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TfiMenu size={22} color="black" />
        </div>
      )}
      <div
        style={{
          width: "40vw",
          display: "flex",
          justifyContent: "flex-start",
          height: "50px",
          marginRight: "auto",
        }}
      >
        {runningApplications.map((application) => (
          <div
            key={application.id}
            onClick={() => handleFocusApplication(application.id)}
            style={{
              width: 50,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: 40,
                height: 40,
                opacity: application.minimize ? 0.5 : 1,
              }}
              src={application.image}
            />
          </div>
        ))}
      </div>
      <div style={{ marginRight: 20 }}>{formatTime(time)}</div>
    </div>
  );
};

export default Taskbar;
