import React, { useState, useEffect } from "react";
import MonacoEditor from "react-monaco-editor";

const VsCode = ({ setUrlbar, appData }) => {
  const [code, setCode] = useState(
    appData ? appData : "// type your code...\n"
  );
  const [output, setOutput] = useState("");

  const options = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: false,
    cursorStyle: "line",
    automaticLayout: true,
  };

  const onChange = (newValue) => {
    setCode(newValue);
  };

  const editorDidMount = (editor) => {
    editor.focus();
  };

  useEffect(() => {
    // Setup a persistent console.log override
    const oldLog = console.log;
    console.log = (message) => {
      setOutput((prevOutput) => prevOutput + message + "\n");
      oldLog(message);
    };

    // Clean up function to restore console.log when component unmounts
    return () => {
      console.log = oldLog;
    };
  }, []);

  const runCode = () => {
    setOutput(""); // Clear previous outputs
    try {
      new Function(code)();
    } catch (e) {
      setOutput("Error: " + e.message);
    }
  };

  return (
    <div style={{ backgroundColor: "#1E1E1E", height: "100%", width: "100%" }}>
      <div
        style={{ height: "70%", width: "100%" }}
        onFocus={() => setUrlbar(true)}
        onBlur={() => setUrlbar(false)}
      >
        <MonacoEditor
          width={"100%"}
          height={"100%"}
          language="javascript"
          theme="vs-dark"
          value={code}
          options={options}
          onChange={onChange}
          editorDidMount={editorDidMount}
        />
      </div>
      <button onClick={runCode} style={{ margin: "10px" }}>
        Run Code
      </button>
      <div
        style={{
          backgroundColor: "#333",
          color: "lime",
          fontFamily: "monospace",
          padding: "10px",
          minHeight: "156px",
          maxHeight: "156px",
          overflowY: "auto",
        }}
      >
        {output || "Terminal output..."}
      </div>
    </div>
  );
};

export default VsCode;
