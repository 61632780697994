import React, { useEffect, useState, useRef } from "react";
import ForYou from "./ForYou";
import AllVideos from "./AllVideos";
import youtubeAnimation from "../youtube.json";
import Lottie from "react-lottie";

const subscriptions = [
  {
    name: "Fireship",
    image: "fireshiplogo.jpg",
    channelId: "UCsBjURrPoezykLs9EqgamOA",
  },
  {
    name: "summit1g",
    image: "summit1g.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "ThinMatrix",
    image: "thinmatrix.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "LTT",
    image: "ltt.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "NahamSec",
    image: "nahamsec.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "FastLaneD",
    image: "fastlanedprofile.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
];

const Youtube = ({ isFullscreen }) => {
  const [videos, setVideos] = useState([]);
  const [channelData, setChannelData] = useState({});
  const [hoveredChannel, setHoveredChannel] = useState("");
  const [smallRes, setSmallRes] = useState(true);
  const [loading, setLoading] = useState(true);
  const divRef = useRef(null);
  const firstRender = useRef(true);

  const searchYoutube = async (channelName) => {
    const youtubeRes = await fetch(
      "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/searchyt",
      {
        method: "POST",
        body: JSON.stringify({ channelName }),
      }
    );

    const data = await youtubeRes.json();

    setVideos(data.videos);

    setChannelData({
      channelBanner: data.channelBanner,
      channelImage: subscriptions.find((sub) => sub.name === channelName).image,
      channelName: channelName,
      channelSubscribersCount: data.channelSubscribersCount,
      channelVideoCount: data.channelVideoCount,
      channelDescription: data.channelDescription,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      searchYoutube("Fireship");
    }
  }, []);

  useEffect(() => {
    if (divRef.current) {
      console.log(divRef.current.offsetWidth);
      if (divRef.current.offsetWidth < 1100) {
        setSmallRes(true);
      } else {
        setSmallRes(false);
      }
    }
  }, [divRef.current?.offsetWidth]);

  return loading ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: youtubeAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={300}
        width={300}
      />
    </div>
  ) : (
    <div
      ref={divRef}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
      }}
    >
      <div
        style={{
          width: "200px",
          borderRight: "1px solid #202020",
          display: "flex",
          flexDirection: "column",
          padding: 12,
        }}
      >
        <div style={{ color: "white", fontWeight: "bold" }}>Subscriptions</div>
        <div>
          {subscriptions.map((sub) => (
            <div
              onMouseEnter={() => setHoveredChannel(sub.name)}
              onMouseLeave={() => setHoveredChannel("")}
              onClick={() => searchYoutube(sub.name)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginTop: 8,
                backgroundColor:
                  hoveredChannel === sub.name
                    ? "rgba(255, 255, 255, 0.2)"
                    : channelData.channelName === sub.name
                    ? "rgba(255, 255, 255, 0.2)"
                    : "",
                padding: 4,
                borderRadius: 5,
              }}
            >
              <img
                src={sub.image}
                alt="sub"
                style={{ height: 24, width: 24, borderRadius: 50 }}
              />
              <div
                style={{ color: "white", marginLeft: 10, fontWeight: "500" }}
              >
                {sub.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          width: isFullscreen ? "90%" : "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            overflow: "auto",
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: "white",
                fontWeight: "bold",
                height: 200,
                width: "80%",
                borderRadius: 5,
                backgroundImage: `url('${channelData.channelBanner}')`,
                backgroundSize: "cover", // Ensures the image covers the entire div
                backgroundPosition: "center", // Centers the background image
              }}
            ></div>
            <div
              style={{
                width: "80%",
                display: "flex",
                height: 140,
                alignItems: "center",
              }}
            >
              <div>
                <img
                  style={{ minWidth: 100, minHeight: 100, borderRadius: 50 }}
                  src={`${channelData.channelImage}`}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 20,
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "0.9em",
                  }}
                >
                  {channelData.channelName}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.9em",
                  }}
                >
                  {`@${channelData.channelName} • ${channelData.channelSubscribersCount} subscribers • ${channelData.channelVideoCount} videos`}
                </div>
              </div>

              <div
                style={{
                  textAlign: "left",
                  width: "50%",
                  marginLeft: "auto",
                  color: "white",
                  fontSize: "0.9em",
                }}
              >
                {smallRes
                  ? channelData.channelDescription?.substring(0, 100) + "..."
                  : channelData.channelDescription?.substring(0, 360)}
              </div>
            </div>
          </div>
          <ForYou videos={videos} />
          <AllVideos videos={videos} />
        </div>
      </div>
    </div>
  );
};

export default Youtube;
