import React from "react";

const Zombie = () => {
  return (
    <div className="flex items-center justify-center w-full h-full bg-gray-900">
      <div className="relative w-full h-full max-w-full max-h-full">
        <iframe
          src="https://html5.gamedistribution.com/39d8fb0fc5784ff5a6445e4f84645f6e/?gd_sdk_referrer_url=https://gamaverse.com/raft-life-game/"
          title="Raft Life"
          className="w-full h-full"
          style={{
            aspectRatio: "16 / 9", // Maintains the aspect ratio for proper scaling
          }}
        />
      </div>
    </div>
  );
};

export default Zombie;
