import React from "react";

const Doom = () => {
  return (
    <iframe
      src={"https://bmsoftware.dev/doom.html"}
      title="Doom"
      style={{ width: "100%", height: "100%" }}
    ></iframe>
  );
};

export default Doom;
