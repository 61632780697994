import React from "react";

const skills0 = [
  "JavaScript",
  "React",
  "React Native",
  "Node.js",
  "CSS",
  "HTML",
  "TailwindCSS",
  "Next.js",
  "Unity Engine",
  "C#",
  "Java",
  "Discord Bots",
  "Slack Bots",
  "Telegram Bots",
  "OpenAI APIs",
];

const hobbies = [
  "Gaming",
  "Coding",
  "Music",
  "Motorcycles",
  "Movies",
  "Bug Bounty Hunting",
];

const MobileAboutMe = () => {
  return (
    <div className="flex flex-col w-full h-full bg-gray-900 text-white p-6 overflow-auto space-y-6">
      {/* Profile image & Basic Info */}
      <div className="flex flex-col items-center bg-gray-800 rounded-lg p-6 shadow-md">
        <img
          src="image0.jpg"
          alt="Brandon Martin"
          className="w-32 h-32 object-cover rounded-full border-4 border-blue-500 shadow-lg"
        />
        <div className="mt-4 text-center leading-6 space-y-2">
          <div className="text-xl font-bold">Brandon Martin</div>
          <div className="text-blue-400">Fullstack Engineer</div>
          <div className="text-gray-400">Orlando, Florida</div>
        </div>
      </div>

      {/* About Me Section */}
      <div className="bg-gray-800 rounded-lg p-6 shadow-md">
        <div className="text-xl font-bold border-b border-gray-700 pb-3 mb-3">
          About Me
        </div>
        <p className="text-gray-300 leading-relaxed">
          My name is Brandon Martin. I am a fullstack engineer with a passion
          for creating and learning. I have been coding for 10 years and have
          been working professionally for 7 years. I have worked on a wide array
          of projects from mobile apps to web apps to games. As a self-taught
          engineer, I take pride in my ability to rapidly acquire and integrate
          new skills without the need for formal instruction. I am a highly
          motivated individual who is always eager to build something new.
        </p>
      </div>

      {/* Skills Section */}
      <div className="bg-gray-800 rounded-lg p-6 shadow-md">
        <div className="text-xl font-bold border-b border-gray-700 pb-3 mb-3">
          What I Know
        </div>
        <div className="flex flex-wrap gap-3">
          {skills0.map((skill, index) => (
            <div
              key={index}
              className="bg-blue-500 text-white text-xs font-medium px-3 py-1 rounded-full shadow-sm"
            >
              {skill}
            </div>
          ))}
        </div>
      </div>

      {/* Hobbies Section */}
      <div className="bg-gray-800 rounded-lg p-6 shadow-md mb-16">
        <div className="text-xl font-bold border-b border-gray-700 pb-3 mb-3">
          My Hobbies
        </div>
        <div className="flex flex-wrap gap-3">
          {hobbies.map((hobby, index) => (
            <div
              key={index}
              className="bg-purple-500 text-white text-xs font-medium px-3 py-1 rounded-full shadow-sm"
            >
              {hobby}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileAboutMe;
