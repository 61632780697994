import React, { useState } from "react";
import Draggable from "react-draggable";
import TerminalApplication from "./Apps/Terminal";
import { TerminalContextProvider } from "react-terminal";
import Contact from "./Apps/Contact";
import AboutMe from "./Apps/AboutMe";
import Chatbot from "./Apps/Chatbot";
import Browser from "./Apps/Browser";
import Paint from "./Apps/Paint";
import Doom from "./Apps/Doom";
import GrandTheftAuto from "./Apps/GrandTheftAuto";
import VsCode from "./Apps/VsCode";
import ImageGenerator from "./Apps/ImageGenerator";
import Projects from "./Apps/Projects";
import SpaceCadet from "./Apps/SpaceCadet";
import MyComputer from "./Apps/MyComputer";
import { VscChromeMaximize } from "react-icons/vsc";
import { LiaWindowMinimize, LiaWindowClose } from "react-icons/lia";
import YouTube from "./Apps/Youtube";
import HackThisTerminalApplication from "./Apps/HackThisTerminal";

const DesktopApplication = ({
  appName,
  handleCloseApplication,
  id,
  value,
  handleMinimizeApplication,
  minimize,
  position,
  updatePosition,
  index,
  setTopApp,
  topApp,
  appImage,
  appData,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [urlbar, setUrlbar] = useState(false);
  const [exitHover, setExitHover] = useState(false);
  const [minimizeHover, setMinimizeHover] = useState(false);
  const [maximizeHover, setMaximizeHover] = useState(false);
  const [dragDisabled, setDragDisabled] = useState(false);

  const handleMaximize = () => {
    if (
      appName !== "About Me" &&
      appName !== "Messenger" &&
      appName !== "Personal Projects"
    ) {
      setIsFullscreen((prevState) => !prevState);
      updatePosition(id, { x: 0, y: 0 });
    }
  };

  const trackPosition = (e, data) => {
    updatePosition(id, { x: data.x, y: data.y });
  };

  return (
    <Draggable
      disabled={urlbar}
      bounds="parent"
      position={position}
      onDrag={trackPosition}
      onMouseDown={() => setTopApp(id)}
    >
      <div
        className="desktop-app"
        style={{
          minWidth: isFullscreen
            ? "100%"
            : appName === "Personal Projects" || appName === "Youtube"
            ? "1000px"
            : appName === "Messenger"
            ? "500px"
            : "800px",
          maxWidth: isFullscreen
            ? "100%"
            : appName === "Personal Projects" || appName === "Youtube"
            ? "1000px"
            : appName === "Messenger"
            ? "500px"
            : "800px",
          minHeight: isFullscreen
            ? `calc(100vh - ${appName === "Terminal" ? 90 : 90}px)`
            : appName === "Personal Projects" || appName === "Youtube"
            ? "700px"
            : "600px",
          maxHeight: isFullscreen
            ? `calc(100vh - ${appName === "Terminal" ? 90 : 90}px)`
            : appName === "Personal Projects" || appName === "Youtube"
            ? "700px"
            : "600px",
          zIndex: topApp === id ? 100 : 10,
          display: minimize ? "none" : "block",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}
      >
        <div className="desktop-app-header">
          <img
            src={appImage}
            alt="app-icon"
            style={{ height: 20, width: 20, marginLeft: 4 }}
          />

          <div
            style={{
              margin: 2,
              color: "white",
              marginLeft: 6,
            }}
          >
            {appName}
          </div>
          <button
            style={{ marginLeft: "auto" }}
            onMouseEnter={() => setMinimizeHover(true)}
            onMouseLeave={() => setMinimizeHover(false)}
            onClick={() => handleMinimizeApplication(id)}
          >
            <LiaWindowMinimize
              color={minimizeHover ? "#FFBD44" : "#A6ACBA"}
              size={22}
            />
          </button>
          <button
            onMouseEnter={() => setMaximizeHover(true)}
            onMouseLeave={() => setMaximizeHover(false)}
            style={{ margin: 2 }}
            onClick={handleMaximize}
          >
            <VscChromeMaximize
              color={maximizeHover ? "#00CA4E" : "#A6ACBA"}
              size={25}
            />
          </button>
          <button
            style={{ margin: 2, marginLeft: 0 }}
            onClick={() => handleCloseApplication(id)}
            onMouseEnter={() => setExitHover(true)}
            onMouseLeave={() => setExitHover(false)}
          >
            <LiaWindowClose
              color={exitHover ? "#FF605C" : "#A6ACBA"}
              size={23}
            />
          </button>
        </div>
        <div
          id="desktop-app-body"
          style={{ height: "100%", width: "100%", backgroundColor: "white" }}
        >
          {appName === "Youtube" && <YouTube isFullscreen={isFullscreen} />}
          {appName === "My Computer" && (
            <MyComputer isFullscreen={isFullscreen} />
          )}
          {appName === "Space Cadet" && (
            <SpaceCadet isFullscreen={isFullscreen} />
          )}
          {appName === "Personal Projects" && (
            <Projects isFullscreen={isFullscreen} />
          )}
          {appName === "AI Image Generator" && (
            <ImageGenerator isFullscreen={isFullscreen} />
          )}
          {appName === "VSCode" && (
            <VsCode
              appData={appData}
              setUrlbar={setUrlbar}
              isFullscreen={isFullscreen}
            />
          )}
          {appName === "Doom" && <Doom isFullscreen={isFullscreen} />}
          {appName === "Paint" && <Paint isFullscreen={isFullscreen} />}
          {appName === "Browser" && (
            <Browser setUrlbar={setUrlbar} isFullscreen={isFullscreen} />
          )}
          {appName === "Terminal" && (
            <TerminalContextProvider>
              <TerminalApplication />
            </TerminalContextProvider>
          )}
          {appName === "GTA Classic" && (
            <GrandTheftAuto isFullscreen={isFullscreen} />
          )}
          {appName === "Messenger" && <Contact isFullscreen={isFullscreen} />}
          {appName === "About Me" && <AboutMe isFullscreen={isFullscreen} />}
          {appName === "AI Chatbot" && <Chatbot isFullscreen={isFullscreen} />}
          {appName === "Hack This Terminal" && (
            <TerminalContextProvider>
              <HackThisTerminalApplication setDragDisabled={setUrlbar} />
            </TerminalContextProvider>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default DesktopApplication;
