import React, { useState } from "react";
import Icon from "./Icon";
import TerminalApplication from "./Apps/Terminal";

const Desktop = ({ handleOpenApplication, setStartMenuActive }) => {
  const [contextMenu, setContextMenu] = useState(null);

  const closeStartMenu = () => {
    setStartMenuActive(false);
    setContextMenu(null);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    setContextMenu({ x: e.clientX, y: e.clientY });
  };

  return (
    <div
      className="Desktop"
      onClick={closeStartMenu}
      onContextMenu={handleContextMenu}
    >
      <Icon
        name="About Me"
        image="portfolio.png"
        handleOpenApplication={handleOpenApplication}
        applicationData={{
          name: "About Me",
          id: 1,
          value: "Welcome to About Me",
          image: "portfolio.png",
        }}
        location={{ x: 0, y: 4 }}
      />
      <Icon
        name="Terminal"
        image="terminal.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "Terminal",
          id: 2,
          value:
            "Welcome to the terminal application... type help to get started!",
          image: "terminal.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 0, y: 10 }}
      />
      <Icon
        name="AI Chatbot"
        image="chatbot.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "AI Chatbot",
          id: 3,
          value: "AI CHATBOT",
          image: "chatbot.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 0, y: 20 }}
      />
      <Icon
        name="Browser"
        image="browserIcon.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "Browser",
          id: 96,
          value: "Browser",
          image: "browserIcon.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 0, y: 30 }}
      />
      <Icon
        name="Paint"
        image="paint.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "Paint",
          id: 39,
          value: "Paint",
          image: "paint.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 0, y: 40 }}
      />
      <Icon
        name="AI Image Generator"
        image="aiimage.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "AI Image Generator",
          id: 334,
          value: "AI Image Generator",
          image: "aiimage.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 0, y: 50 }}
      />
      <Icon
        name="Youtube"
        image="youtube.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "Youtube",
          id: 444,
          value: "Youtube",
          image: "youtube.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 100, y: -566 }}
      />
      <Icon
        name="Messenger"
        image="gmail.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "Messenger",
          id: 22,
          value: "Messenger",
          image: "gmail.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 100, y: -560 }}
      />
      <Icon
        name="Personal Projects"
        image="ui-design.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "Personal Projects",
          id: 5,
          value: "Personal Projects",
          image: "ui-design.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 100, y: -550 }}
      />

      <Icon
        name="GTA Classic"
        image="gta.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "GTA Classic",
          id: 6,
          value: "GTA Classic",
          image: "gta.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 100, y: -540 }}
      />

      <Icon
        name="VSCode"
        image="vscode.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "VSCode",
          id: 723,
          value: "VSCode",
          image: "vscode.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 100, y: -530 }}
      />

      <Icon
        name="Doom"
        image="doom.png"
        applicationComponent={<TerminalApplication />}
        applicationData={{
          name: "Doom",
          id: 12,
          value: "Doom",
          image: "doom.png",
        }}
        handleOpenApplication={handleOpenApplication}
        location={{ x: 100, y: -530 }}
      />
    </div>
  );
};

export default Desktop;
