import React from "react";
import Lottie from "react-lottie";
import helpAnimation from "./404.json";

const MyComputer = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Lottie
        height={200}
        width={200}
        style={{ marginTop: -20 }}
        options={{ loop: true, autoplay: true, animationData: helpAnimation }}
      />
      <div style={{ width: "60%", textAlign: "center" }}>
        This application is still under development and is not available yet!
        Full filesystem management coming soon.
      </div>
    </div>
  );
};

export default MyComputer;
