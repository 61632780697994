import React from "react";
import Lottie from "react-lottie";
import thunderAnimation from "../../thunder.json";

// Helper function for AM/PM formatting
const formatAMPM = (date) => {
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${hours} ${ampm}`;
};

// Generate next 10 hours of forecast data
const generateHourlyForecast = () => {
  const now = new Date();
  const hourlyData = [];

  for (let i = 0; i < 10; i++) {
    const forecastTime = new Date(now.getTime() + i * 60 * 60 * 1000);
    const baseTemp = 76;
    const tempDrop = i > 0 ? i : 0;
    const temp = `${baseTemp - tempDrop}°`;
    const icon = i < 3 ? "⛈️" : "🌦️"; // e.g., first few hours rainy, then clearing up

    hourlyData.push({
      time: formatAMPM(forecastTime),
      temp,
      icon,
    });
  }

  return hourlyData;
};

const MobileWeather = () => {
  const weatherData = {
    location: "Orlando",
    temperature: "76°",
    condition: "Rain & Thunderstorms",
    highLow: "H:78° L:62°",
    description:
      "Expect showers and occasional thunderstorms in the next few hours, with gusty winds up to 21 mph. Rain activity will gradually subside, giving way to partly cloudy skies.",
    // Dynamically generate the next 10 hours
    hourlyForecast: generateHourlyForecast(),
    dailyForecast: [
      {
        day: "Today",
        high: "78°",
        low: "62°",
        icon: "⛈️",
        rainChance: "80%", // Matches the overnight/morning rain
      },
      {
        day: "Fri",
        high: "80°",
        low: "64°",
        icon: "🌦️",
        rainChance: "50%", // Some lingering morning showers
      },
      {
        day: "Sat",
        high: "82°",
        low: "66°",
        icon: "⛅",
        rainChance: "30%", // Typical Florida partial cloud cover
      },
      {
        day: "Sun",
        high: "84°",
        low: "68°",
        icon: "☀️",
        rainChance: "20%", // Mostly sunny and warm
      },
      {
        day: "Mon",
        high: "85°",
        low: "69°",
        icon: "🌦️",
        rainChance: "40%", // Scattered showers
      },
      {
        day: "Tue",
        high: "87°",
        low: "70°",
        icon: "🌩️",
        rainChance: "70%", // Thunderstorms
      },
      {
        day: "Wed",
        high: "86°",
        low: "72°",
        icon: "⛅",
        rainChance: "20%", // Partly cloudy
      },
      {
        day: "Thu",
        high: "88°",
        low: "73°",
        icon: "☀️",
        rainChance: "10%", // Mostly sunny
      },
      {
        day: "Fri",
        high: "83°",
        low: "65°",
        icon: "🌧️",
        rainChance: "60%", // Rainy
      },
      {
        day: "Sat",
        high: "84°",
        low: "68°",
        icon: "☀️",
        rainChance: "0%", // Clear skies
      },
    ],
  };

  return (
    <div className="h-[1100px] bg-gradient-to-b from-blue-900 via-indigo-900 to-black text-white p-4 flex flex-col">
      {/* Main Weather Info */}
      <div className="mt-4">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: thunderAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={120}
          width={200}
        />
      </div>
      <div className="flex flex-col items-center mt-6">
        <h1 className="text-4xl font-semibold">{weatherData.location}</h1>
        <p className="text-7xl font-thin">{weatherData.temperature}</p>
        <p className="text-lg capitalize">{weatherData.condition}</p>
        <p className="text-sm mt-1">{weatherData.highLow}</p>
        <p className="text-sm mt-4 text-center text-gray-300">
          {weatherData.description}
        </p>
      </div>

      {/* Hourly Forecast */}
      <div className="mt-6">
        <div className="flex justify-between overflow-x-auto scrollbar-hide">
          {weatherData.hourlyForecast.map((hour, index) => (
            <div
              key={index}
              className="flex flex-col items-center mx-2 min-w-[50px]"
            >
              <p className="text-sm">{hour.time}</p>
              <p className="text-2xl">{hour.icon}</p>
              <p className="text-sm">{hour.temp}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Divider */}
      <div className="h-px bg-white bg-opacity-30 my-6"></div>

      {/* Daily Forecast */}
      <div>
        <p className="text-sm text-gray-300 uppercase tracking-wider mb-2">
          10-day forecast
        </p>
        {weatherData.dailyForecast.map((day, index) => (
          <div
            key={index}
            className="flex items-center py-2 border-b border-white border-opacity-20"
          >
            <p className="text-sm w-12">{day.day}</p>
            <div className="flex items-center gap-2">
              <span className="text-xl ml-4">{day.icon}</span>
              {day.rainChance && (
                <span className="text-sm text-blue-300">{day.rainChance}</span>
              )}
            </div>
            <p className="text-sm ml-auto">{`${day.low} / ${day.high}`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileWeather;
