import React, { useEffect, useState, useRef } from "react";
import ForYou from "./ForYou";
import AllVideos from "./AllVideos";
import Lottie from "react-lottie";
import youtubeAnimation from "../../youtube.json";

const subscriptions = [
  {
    name: "Fireship",
    image: "fireshiplogo.jpg",
    channelId: "UCsBjURrPoezykLs9EqgamOA",
  },
  {
    name: "summit1g",
    image: "summit1g.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "ThinMatrix",
    image: "thinmatrix.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "LTT",
    image: "ltt.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "NahamSec",
    image: "nahamsec.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
  {
    name: "FastLaneD",
    image: "fastlanedprofile.jpg",
    channelId: "UCOy7Ox4MnxMw05cZ8fWbbMg",
  },
];

const MobileYoutube = ({ isFullscreen }) => {
  const [videos, setVideos] = useState([]);
  const [channelData, setChannelData] = useState({});
  const [hoveredChannel, setHoveredChannel] = useState("");
  const [smallRes, setSmallRes] = useState(true);
  const [loading, setLoading] = useState(true);

  const containerRef = useRef(null);
  const firstRender = useRef(true);

  const searchYoutube = async (channelName) => {
    setLoading(true);

    const youtubeRes = await fetch(
      "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/searchyt",
      {
        method: "POST",
        body: JSON.stringify({ channelName }),
      }
    );
    const data = await youtubeRes.json();

    setVideos(data.videos);

    setChannelData({
      channelBanner: data.channelBanner,
      channelImage: subscriptions.find((sub) => sub.name === channelName).image,
      channelName: data.channelName,
      channelSubscribersCount: data.channelSubscribersCount,
      channelVideoCount: data.channelVideoCount,
      channelDescription: data.channelDescription,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      searchYoutube("Fireship");
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      setSmallRes(width < 1100);
    }
  }, [containerRef.current?.offsetWidth]);

  return (
    <>
      {loading ? (
        <div className="w-full h-full bg-gray-900 flex items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: youtubeAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={200}
            width={200}
          />
        </div>
      ) : (
        <div
          ref={containerRef}
          className="w-full h-full bg-gray-900 flex flex-col"
        >
          <div className="text-white font-bold text-xl p-4 border-b border-gray-700 shadow-lg">
            Subscriptions
          </div>
          <div className="flex items-center px-4 py-3 space-x-4 overflow-x-auto border-b border-gray-700 scrollbar-hide">
            {subscriptions.map((sub) => {
              const isHover = hoveredChannel === sub.name;
              const isActive = channelData.channelName === sub.name;
              return (
                <div
                  key={sub.name}
                  onMouseEnter={() => setHoveredChannel(sub.name)}
                  onMouseLeave={() => setHoveredChannel("")}
                  onClick={() => searchYoutube(sub.name)}
                  className={`flex flex-col items-center min-w-[60px] p-2 rounded-lg
                    cursor-pointer transition-transform duration-200 transform
                    ${
                      isHover || isActive
                        ? "bg-gray-800 scale-110"
                        : "bg-transparent"
                    }`}
                >
                  <img
                    src={sub.image}
                    alt="sub"
                    className="h-12 w-12 rounded-full shadow-lg"
                  />
                  <div className="text-gray-300 text-xs mt-2 font-medium">
                    {sub.name}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex-1 overflow-auto pb-4">
            <div className="flex flex-col items-center mt-6 px-4">
              <div
                className="w-full h-44 sm:h-56 bg-center bg-cover rounded-lg shadow-md border-gray-700 border"
                style={{
                  backgroundImage: `url('${channelData.channelBanner}')`,
                }}
              ></div>

              <div className="flex items-center w-full mt-6">
                <img
                  src={channelData.channelImage}
                  alt="Channel"
                  className="w-16 h-16 rounded-full shadow-lg border-2 border-gray-700"
                />

                <div className="flex flex-col ml-4">
                  <div className="text-white font-bold text-lg mb-1">
                    {channelData.channelName}
                  </div>
                  <div className="text-gray-400 text-sm">
                    @{channelData.channelName} •{" "}
                    {channelData.channelSubscribersCount} subscribers •{" "}
                    {channelData.channelVideoCount} videos
                  </div>
                </div>
              </div>

              <div className="w-full mt-4 text-gray-300 text-sm text-justify leading-6">
                {smallRes
                  ? channelData.channelDescription?.substring(0, 100) + "..."
                  : channelData.channelDescription?.substring(0, 360)}
              </div>
            </div>

            <ForYou videos={videos} />
            <AllVideos videos={videos} />
          </div>
        </div>
      )}
    </>
  );
};

export default MobileYoutube;
