import React, { useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IoMdSend } from "react-icons/io";
import firebase from "firebase/compat/app";

const Contact = () => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  const signInAnonymouslyHandler = async () => {
    try {
      if (auth.currentUser) {
        return;
      }

      const userCredential = await auth.signInAnonymously();
      const user = userCredential.user;
      setUser(user.uid);
    } catch (error) {
      console.error("Error signing in anonymously:", error);
    }
  };

  const getMessages = async (uid) => {
    try {
      if (!uid) {
        return;
      }

      const messages = await db.collection("Messages").doc(uid).get();
      if (messages.exists) {
        setMessages(messages.data().messages);
      }
    } catch (error) {
      console.error("Error getting messages:", error);
    }
  };

  useEffect(() => {
    signInAnonymouslyHandler();

    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user.uid);
        getMessages(user.uid);
      }
    });
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessageHandler = async () => {
    try {
      const trimmedMessage = message.trim();
      if (trimmedMessage.length === 0) return;
      setMessage("");
      if (!user) {
        return;
      }

      const messageObject = {
        message: trimmedMessage,
        timestamp: new Date().getTime(),
        from: "user",
      };


      let ms = [...messages, messageObject];

      const initialMessagesLength = messages.length;

      setMessages(ms);


      const res = await fetch(
        "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/createDiscordChannel",
        {
          method: "POST",
          body: JSON.stringify({
            customerId: user, customerName: "Portfolio User", initialMessage: trimmedMessage
          }),
        }
      );

      console.log(res.status)

      if (initialMessagesLength === 0) {
        setTimeout(async () => {
          const autoReply = {
            message:
              "Hello! Thanks for messaging me. I'll get an alert for this message and will respond as soon as I can. Thank you!",
            timestamp: new Date().getTime(),
            from: "brandon",
          };

          setMessages([...ms, autoReply]);
          await db
            .collection("Messages")
            .doc(user)
            .set(
              {
                messages: firebase.firestore.FieldValue.arrayUnion(autoReply),
              },
              { merge: true }
            );
        }, 3000);
      }



    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div
      style={{ width: "100%", height: "100%", backgroundColor: "black" }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div
        style={{
          height: "80px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          borderBottom: "1px solid #202020",
          backgroundImage: "url('pixelbg.gif')",
          backgroundPosition: "center 80%",
          backgroundSize: "cover",
        }}
      >
        <div style={{ marginLeft: 10 }}>
          <img
            style={{
              width: 60,
              height: 60,
              borderRadius: 50,
              border: "2px solid white",
            }}
            src="image0.jpg"
            alt="Brandon Martin"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              color: "white",
              marginLeft: 10,
              fontWeight: "bold",
              fontSize: 20,
              textShadow: "2px 2px 2px #000000",
            }}
          >
            Brandon Martin
          </div>
          <div
            style={{
              color: "white",
              marginLeft: 10,
              fontWeight: "bold",
              fontSize: 14,
              textShadow: "2px 2px 2px #000000",
            }}
          >
            Self-Taught Fullstack Engineer
          </div>
        </div>
      </div>
      <div style={{ height: 452, overflow: "auto", marginTop: 8 }}>
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent:
                message.from === "user" ? "flex-end" : "flex-start",
              padding: 10,
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor:
                  message.from === "user" ? "#3B3B3B" : "#1F57E7",
                color: "white",
                padding: 10,
                borderRadius: 5,
              }}
            >
              {message.message}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div
        style={{
          height: 60,
          width: "100%",
          padding: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          placeholder="Enter message here..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessageHandler();
            }
          }}
          style={{
            height: "100%",
            width: "90%",
            borderRadius: 5,
            padding: 4,
            outline: "none",
            color: "white",
          }}
        />
        <div
          onClick={() => sendMessageHandler()}
          style={{
            width: "10%",
            backgroundColor: "#3B3B3B",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 8,
            borderRadius: 5,
            height: 40,
            cursor: "pointer",
          }}
        >
          <IoMdSend size={30} color="white" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
