import React, { useState, useEffect } from "react";
import {
  AiOutlineClockCircle,
  AiOutlineUser,
  AiOutlineMail,
} from "react-icons/ai";
import { FiPhoneOff } from "react-icons/fi";

// =======================
// 1. Global Contacts
// =======================
const GLOBAL_CONTACTS = [
  {
    name: "Peter Griffin",
    photo: "peter.webp",
    callAudio: "peter.mp3",
  },
  {
    name: "Grandma Edna",
    photo: "edna.webp",
    callAudio: "edna.mp3",
  },
  {
    name: "Spongebob",
    photo: "spongebob.webp",
    callAudio: "spongebob.mp3",
  },
  {
    name: "Eric Cartman",
    photo: "eric.webp",
    callAudio: "eric.mp3",
  },
];

// =======================
// Helper Functions
// =======================

// Returns a random date/time string like "Today, 3:30 PM"
const getRandomTimeString = () => {
  const now = new Date();
  const pastTime =
    now.getTime() - Math.floor(Math.random() * 24 * 60 * 60 * 1000);
  const randomDate = new Date(pastTime);

  const hours = randomDate.getHours();
  const minutes = randomDate.getMinutes().toString().padStart(2, "0");
  let ampm = "AM";

  let displayHours = hours;
  if (hours === 0) {
    displayHours = 12; // Midnight is 12 AM
  } else if (hours > 12) {
    displayHours = hours - 12;
    ampm = "PM";
  } else if (hours === 12) {
    ampm = "PM";
  }

  return `Today, ${displayHours}:${minutes} ${ampm}`;
};

// Returns one of "Missed", "Inbound", or "Outbound"
const getRandomCallType = () => {
  const types = ["Missed", "Inbound", "Outbound"];
  return types[Math.floor(Math.random() * types.length)];
};

// Generates random recents from the global contacts
const generateRandomRecents = () => {
  const recents = [];
  for (let i = 0; i < 5 + Math.floor(Math.random() * 2); i++) {
    const contact =
      GLOBAL_CONTACTS[Math.floor(Math.random() * GLOBAL_CONTACTS.length)];
    recents.push({
      name: contact.name,
      photo: contact.photo,
      callAudio: contact.callAudio,
      time: getRandomTimeString(),
      type: getRandomCallType(),
    });
  }
  return recents;
};

// Generates random voicemails from the global contacts
const generateRandomVoicemails = () => {
  const voicemails = [];
  for (let i = 0; i < 3 + Math.floor(Math.random() * 3); i++) {
    const contact =
      GLOBAL_CONTACTS[Math.floor(Math.random() * GLOBAL_CONTACTS.length)];
    voicemails.push({
      name: contact.name,
      photo: contact.photo,
      time: getRandomTimeString(),
    });
  }
  return voicemails;
};

// =======================
// Main Phone Component
// =======================
const Phone = () => {
  const [activeTab, setActiveTab] = useState("contacts");
  const [activeContact, setActiveContact] = useState(null);

  const [recentsData, setRecentsData] = useState(generateRandomRecents());
  const [voicemailData, setVoicemailData] = useState(
    generateRandomVoicemails()
  );

  const handleRecentClick = (recent) => {
    setActiveContact(recent);
  };

  const TabContent = () => {
    if (activeContact) {
      return (
        <CallingScreen
          contact={activeContact}
          onCallEnd={() => {
            setActiveContact(null);
            setActiveTab("contacts");
          }}
        />
      );
    }

    switch (activeTab) {
      case "recents":
        return (
          <Recents
            recentsData={recentsData}
            onRecentClick={handleRecentClick}
          />
        );
      case "contacts":
        return <Contacts onContactSelect={setActiveContact} />;
      case "voicemail":
        return <Voicemail voicemailData={voicemailData} />;
      default:
        return null;
    }
  };

  const isCallActive = !!activeContact;

  return (
    <div className="h-full w-full bg-gray-900 text-white flex flex-col">
      {/* Main Content */}
      <div className="flex-grow overflow-auto">
        <TabContent />
      </div>

      {/* Bottom Navigation */}
      <div className="flex justify-around items-center bg-gray-800 py-4 border-t border-gray-700">
        <button
          className={`flex flex-col items-center text-sm transition-all transform ${
            activeTab === "recents"
              ? "text-blue-400 scale-110"
              : "text-gray-400 hover:text-white"
          } ${isCallActive ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={() => {
            if (!isCallActive) setActiveTab("recents");
          }}
        >
          <AiOutlineClockCircle className="text-3xl mb-1" />
          Recents
        </button>
        <button
          className={`flex flex-col items-center text-sm transition-all transform ${
            activeTab === "contacts"
              ? "text-blue-400 scale-110"
              : "text-gray-400 hover:text-white"
          } ${isCallActive ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={() => {
            if (!isCallActive) setActiveTab("contacts");
          }}
        >
          <AiOutlineUser className="text-3xl mb-1" />
          Contacts
        </button>
        <button
          className={`flex flex-col items-center text-sm transition-all transform ${
            activeTab === "voicemail"
              ? "text-blue-400 scale-110"
              : "text-gray-400 hover:text-white"
          } ${isCallActive ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={() => {
            if (!isCallActive) setActiveTab("voicemail");
          }}
        >
          <AiOutlineMail className="text-3xl mb-1" />
          Voicemail
        </button>
      </div>
    </div>
  );
};

// =======================
// Recents Tab
// =======================
const Recents = ({ recentsData, onRecentClick }) => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-4">Recents</h1>
      <div className="space-y-6">
        {recentsData.map((call, index) => (
          <div
            key={index}
            className="flex justify-between items-center cursor-pointer p-4 rounded-lg bg-gray-800 hover:bg-gray-700 transition-all shadow-lg"
            onClick={() => onRecentClick(call)}
          >
            <div className="flex items-center space-x-4">
              <img
                src={call.photo}
                alt={call.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div>
                <p className="text-lg font-medium">{call.name}</p>
                <p className="text-sm text-gray-400">{call.time}</p>
              </div>
            </div>
            <p
              className={`text-sm font-semibold ${
                call.type === "Missed"
                  ? "text-red-500"
                  : call.type === "Inbound"
                  ? "text-green-400"
                  : "text-blue-400"
              }`}
            >
              {call.type}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

// =======================
// Contacts Tab
// =======================
const Contacts = ({ onContactSelect }) => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-4">Contacts</h1>
      <div className="space-y-6">
        {GLOBAL_CONTACTS.map((contact, index) => (
          <div
            key={index}
            className="flex items-center space-x-6 cursor-pointer p-4 rounded-lg bg-gray-800 hover:bg-gray-700 transition-all shadow-lg"
            onClick={() => onContactSelect(contact)}
          >
            <img
              src={contact.photo}
              alt={contact.name}
              className="w-12 h-12 rounded-full object-cover"
            />
            <p className="text-lg font-medium">{contact.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

// =======================
// Voicemail Tab
// =======================
const Voicemail = ({ voicemailData }) => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-4">Voicemail</h1>
      <div className="space-y-6">
        {voicemailData.map((vm, index) => (
          <div
            key={index}
            className="flex justify-between items-center p-4 rounded-lg bg-gray-800 hover:bg-gray-700 transition-all shadow-lg"
          >
            <div className="flex items-center space-x-4">
              <img
                src={vm.photo}
                alt={vm.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div>
                <p className="text-lg font-medium">{vm.name}</p>
                <p className="text-sm text-gray-400">{vm.time}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// =======================
// Calling Screen
// =======================
const CallingScreen = ({ contact, onCallEnd }) => {
  const [calloutAudio, setCalloutAudio] = useState(null);
  const [contactAudio, setContactAudio] = useState(null);

  const [callState, setCallState] = useState("calling");
  const [callDuration, setCallDuration] = useState(0);

  const { name, photo, callAudio } = contact;

  useEffect(() => {
    const callout = new Audio("callout.mp3");
    const answerAudio = new Audio(callAudio);

    setCalloutAudio(callout);
    setContactAudio(answerAudio);

    const playAudio = () => {
      const calloutPromise = callout.play();
      if (calloutPromise !== undefined) {
        calloutPromise.catch((error) => {
          if (error.name !== "AbortError") {
            console.error(error);
          }
        });
      }

      callout.onended = () => {
        setCallState("inCall");

        const answerPromise = answerAudio.play();
        if (answerPromise !== undefined) {
          answerPromise.catch((error) => {
            if (error.name !== "AbortError") {
              console.error(error);
            }
          });
        }
        answerAudio.onended = () => {
          onCallEnd();
        };
      };
    };

    playAudio();

    return () => {
      callout.pause();
      callout.currentTime = 0;
      answerAudio.pause();
      answerAudio.currentTime = 0;
    };
  }, [callAudio, onCallEnd]);

  useEffect(() => {
    let timer;
    if (callState === "inCall") {
      timer = setInterval(() => {
        setCallDuration((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [callState]);

  const handleManualEndCall = () => {
    if (calloutAudio) {
      calloutAudio.pause();
      calloutAudio.currentTime = 0;
    }
    if (contactAudio) {
      contactAudio.pause();
      contactAudio.currentTime = 0;
    }
    onCallEnd();
  };

  const formatCallDuration = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${m}:${s.toString().padStart(2, "0")}`;
  };

  return (
    <div className="h-full w-full flex flex-col items-center justify-center bg-gray-900 text-white">
      <img
        src={photo}
        alt={name}
        className="w-40 h-40 rounded-full object-cover mb-6 shadow-lg"
      />
      <h1 className="text-3xl font-bold mb-4">{name}</h1>

      {callState === "calling" ? (
        <p className="text-lg text-gray-400 mb-6">Calling mobile...</p>
      ) : (
        <p className="text-lg text-green-400 mb-6">
          In Call — {formatCallDuration(callDuration)}
        </p>
      )}

      <div className="grid grid-cols-3 gap-6 mb-12">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-gray-700 rounded-full flex items-center justify-center shadow-md">
            <p className="text-xs">Mute</p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-gray-700 rounded-full flex items-center justify-center shadow-md">
            <p className="text-xs">Keypad</p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-gray-700 rounded-full flex items-center justify-center shadow-md">
            <p className="text-xs">Audio</p>
          </div>
        </div>
      </div>

      <button
        className="w-16 h-16 bg-red-600 rounded-full flex items-center justify-center shadow-lg"
        onClick={handleManualEndCall}
      >
        <FiPhoneOff className="text-white text-3xl" />
      </button>
    </div>
  );
};

export default Phone;
