import React, { useState, useRef, useEffect } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

const AllVideos = ({ videos: initialVideos }) => {
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(4);
  const divRef = useRef(null);

  // Shuffle and store videos on mount or prop change
  useEffect(() => {
    if (initialVideos) {
      const sorted = [...initialVideos].sort(() => Math.random() - 0.5);
      setVideos(sorted);
    }
  }, [initialVideos]);

  // Move forward/back by 'displayCount' videos
  const nextVideos = () => {
    if (currentIndex + displayCount < videos.length) {
      setCurrentIndex(currentIndex + displayCount);
    }
  };

  const prevVideos = () => {
    if (currentIndex - displayCount >= 0) {
      setCurrentIndex(currentIndex - displayCount);
    }
  };

  // Dynamically adjust 'displayCount' based on container width
  const handleResize = () => {
    if (divRef.current) {
      const width = divRef.current.offsetWidth;
      if (width < 640) {
        // small screens
        setDisplayCount(1);
      } else if (width < 1024) {
        // medium screens
        setDisplayCount(2);
      } else {
        // large screens
        setDisplayCount(4);
      }
    }
  };

  // Run on mount + whenever screen size changes
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={divRef}
      className="w-[90%] flex flex-col items-start mx-auto mt-12 bg-gray-800/90 rounded-xl p-2 shadow-lg border-gray-700 border"
    >
      {/* Section Title */}
      <div className="text-white font-bold ml-2">All Videos</div>

      {/* Video Slider */}
      <div className="relative w-full flex items-center justify-center">
        {/* Left Arrow */}
        <div className="absolute left-8 top-1/2 -translate-y-1/2 -translate-x-[40px] z-10">
          <FaArrowCircleLeft
            onClick={prevVideos}
            className="text-white text-3xl sm:text-4xl cursor-pointer opacity-40"
          />
        </div>

        {/* Videos Container */}
        <div className="flex w-full overflow-hidden justify-center space-x-2 sm:space-x-4">
          {videos
            .slice(currentIndex, currentIndex + displayCount)
            .map((video, idx) => (
              <div
                key={idx}
                className="flex-grow flex flex-col min-w-0 m-2 sm:m-3"
              >
                <iframe
                  src={`https://www.youtube.com/embed/${video}`}
                  title={`video-${idx}`}
                  className="w-full h-48 sm:h-56 md:h-64"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            ))}
        </div>

        {/* Right Arrow */}
        <div className="absolute right-8 top-1/2 -translate-y-1/2 translate-x-[40px] z-10">
          <FaArrowCircleRight
            onClick={nextVideos}
            className="text-white text-3xl sm:text-4xl cursor-pointer opacity-40"
          />
        </div>
      </div>
    </div>
  );
};

export default AllVideos;
