import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import Lottie from "react-lottie";
import animationData from "../helper1.json";

const ImageGenerator = () => {
  const [inputText, setInputText] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");

  const generateImage = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (inputText.trim().length === 0)
        return alert("Please enter a description.");
      setLoading(true);
      const uInput = inputText;
      setPrompt(uInput);
      setInputText("");

      try {
        const response = await fetch(
          "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/generateImage",
          {
            method: "POST",
            body: JSON.stringify({
              prompt: uInput,
            }),
          }
        );
        const data = await response.json();
        setImage(data.image);
        setLoading(false);
      } catch (err) {
        console.log(err);
        alert("An error occurred. Please try again.");
        setLoading(false);
      }
    } else if (event.type === "click") {
      if (inputText.trim().length === 0)
        return alert("Please enter a description.");
      setLoading(true);
      const uInput = inputText;
      setPrompt(uInput);
      setInputText("");

      try {
        const response = await fetch(
          "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/generateImage",
          {
            method: "POST",
            body: JSON.stringify({
              prompt: uInput,
            }),
          }
        );
        const data = await response.json();
        setImage(data.image);
        setLoading(false);
      } catch (err) {
        console.log(err);
        alert("An error occurred. Please try again.");
        setLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#444654",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          height: "80%",
          width: "100%",
          paddingRight: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {loading && (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={300}
            width={300}
          />
        )}

        {loading && (
          <div>
            <h1 style={{ color: "white", marginTop: -30 }}>
              Generating Image...
            </h1>
          </div>
        )}

        {!loading && image && (
          <img
            src={image}
            style={{
              width: "100%",
              height: "80%",
              objectFit: "contain",
              borderRadius: 5,
            }}
          />
        )}

        {!loading && image && (
          <div style={{ color: "white", marginTop: 8 }}>{prompt}</div>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "80%",
          height: 40,
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <textarea
          value={inputText}
          disabled={loading}
          placeholder="Enter image description here..."
          onKeyDown={(event) => generateImage(event)}
          onChange={(event) => setInputText(event.target.value)}
          style={{
            width: "100%",
            height: 40,
            backgroundColor: "#40414F",
            borderRadius: 5,
            outline: "none",
            resize: "none",
            color: "white",
            padding: 6,
          }}
        />
        <div
          onClick={(event) => (loading ? null : generateImage(event))}
          style={{
            height: 42,
            width: 42,
            backgroundColor: "#40414F",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid white",
            borderRadius: 5,
            marginLeft: 5,
            cursor: "pointer",
          }}
        >
          <AiOutlineSend />
        </div>
      </div>
    </div>
  );
};

export default ImageGenerator;
