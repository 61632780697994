import React from "react";

const skills0 = [
  "JavaScript",
  "React",
  "React Native",
  "Node.js",
  "CSS",
  "HTML",
  "TailwindCSS",
  "Next.js",
  "Unity Engine",
  "C#",
  "Java",
  "Discord Bots",
  "Slack Bots",
  "Telegram Bots",
  "OpenAI APIs",
  // add as many as you like
];

const hobbies = [
  "Gaming",
  "Coding",
  "Music",
  "Motorcycles",
  "Movies",
  "Bug Bounty Hunting",
];

const AboutMe = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%", // changed width to 100%
        backgroundColor: "#202020",
        borderTop: "1px solid white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 20,
        }}
      >
        <img
          style={{ width: 360, borderRadius: 5, border: "1px solid white" }}
          src={"image0.jpg"}
        />
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginTop: 15,
          }}
        >
          <div style={{ color: "white", fontSize: 14, margin: 4 }}>
            Brandon Martin -
          </div>
          <div style={{ color: "white", fontSize: 14, margin: 4 }}>
            Fullstack Engineer -
          </div>
          <div style={{ color: "white", fontSize: 14, margin: 4 }}>
            Orlando, Florida.
          </div>
        </div>
      </div>

      <div
        style={{
          marginLeft: 40,
          border: "1px solid white",
          width: "44%",
          height: "92%",
          borderRadius: 5,
        }}
      >
        <div
          style={{
            color: "white",
            borderBottom: "1px solid white",
            height: "40%",
            padding: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ display: "flex", fontSize: 13 }}>
            My name is Brandon Martin. I am a fullstack engineer with a passion
            for creating and learning. I have been coding for 10 years and have
            been working professionally for 7 years. I have worked on a wide
            array of projects from mobile apps to web apps to games. As a
            self-taught engineer, I take pride in my ability to rapidly acquire
            and integrate new skills without the need for formal instruction. I
            am a highly motivated individual who is always eager to build
            something new.
          </div>
        </div>
        <div
          style={{
            color: "white",
            borderBottom: "1px solid white",
            height: "30%",
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-center",
            paddingTop: 10,
            fontSize: 14,
          }}
        >
          <div style={{ marginLeft: 4 }}>What I Know:</div>
          <div
            style={{
              display: "flex",
              marginTop: 4,
              flexWrap: "wrap",
            }}
          >
            {skills0.map((skill, index) => (
              <div
                style={{
                  fontSize: 14,
                  marginLeft: 4,
                }}
                key={index}
              >
                {skill}
                {index === skills0.length - 1 ? "." : ","}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            color: "white",
            borderBottom: "1px solid white",
            height: "30%",
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-center",
            paddingTop: 10,
            fontSize: 14,
          }}
        >
          <div style={{ marginLeft: 4 }}>My Hobbies:</div>
          <div
            style={{
              display: "flex",
              marginTop: 4,
              flexWrap: "wrap",
            }}
          >
            {hobbies.map((skill, index) => (
              <div
                style={{
                  fontSize: 14,
                  marginLeft: 4,
                }}
                key={index}
              >
                {skill}
                {index === hobbies.length - 1 ? "." : ","}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
