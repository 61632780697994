import React, { useState } from "react";
import { AiOutlineRight, AiOutlineWifi, AiOutlineLeft } from "react-icons/ai";
import { BsAirplane } from "react-icons/bs";
import { BiBluetooth } from "react-icons/bi";
import { MdCellTower } from "react-icons/md";
import { IoBatteryFull } from "react-icons/io5";

// Simple toggle component
const Toggle = ({ enabled, onChange }) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={enabled}
        onChange={onChange}
      />
      <div className="w-11 h-6 bg-gray-300 rounded-full peer  peer-focus:ring-2 peer-focus:ring-blue-500 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:left-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
    </label>
  );
};

// Submenu Screen Example
const GeneralScreen = ({ onBack }) => {
  return (
    <div className="h-full w-full flex flex-col bg-black text-white">
      {/* Header with back button */}
      <div className="flex items-center px-4 py-3 border-b border-gray-800 bg-gray-900">
        <button onClick={onBack} className="mr-4">
          <AiOutlineLeft className="text-2xl" />
        </button>
        <h2 className="text-xl font-bold">General</h2>
      </div>

      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto">
        <div className="px-4 mt-4">
          <div className="bg-gray-900 rounded-lg overflow-hidden mb-4">
            <button className="flex items-center justify-between w-full p-3 border-b border-gray-800 hover:bg-gray-800">
              <span className="text-sm">Software Update</span>
              <AiOutlineRight />
            </button>

            <button className="flex items-center justify-between w-full p-3 border-b border-gray-800 hover:bg-gray-800">
              <span className="text-sm">Storage</span>
              <AiOutlineRight />
            </button>

            <button className="flex items-center justify-between w-full p-3 hover:bg-gray-800">
              <span className="text-sm">Reset</span>
              <AiOutlineRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Another Submenu Screen Example
const WallpapersScreen = ({
  onBack,
  selectedWallpaper,
  setSelectedWallpaper,
}) => {
  // Keep track of the currently selected wallpaper by ID

  // List of wallpapers - replace these with your own images/names
  const wallpapers = [
    {
      id: 1,
      name: "Abstract Stones",
      src: "wallpaper1.jpg",
    },
    {
      id: 2,
      name: "Apple Metal",
      src: "wallpaper2.jpg",
    },
    {
      id: 3,
      name: "Stones",
      src: "wallpaper3.jpg",
    },
    {
      id: 4,
      name: "Northern Lights",
      src: "wallpaper4.jpg",
    },
    {
      id: 5,
      name: "Starry Sky",
      src: "wallpaper5.jpg",
    },
  ];

  const handleSelect = (wallpaperId) => {
    setSelectedWallpaper(wallpaperId);
  };

  return (
    <div className="h-full w-full flex flex-col bg-black text-white">
      {/* Header */}
      <div className="flex items-center px-4 py-3 border-b border-gray-800 bg-gray-900">
        <button onClick={onBack} className="mr-4">
          <AiOutlineLeft className="text-2xl" />
        </button>
        <h2 className="text-xl font-bold">Wallpapers</h2>
      </div>

      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto px-4 py-4">
        {/* We can render a grid of wallpapers */}
        <div className="grid grid-cols-2 gap-4">
          {wallpapers.map((wallpaper) => (
            <button
              key={wallpaper.id}
              onClick={() => handleSelect(wallpaper.id)}
              className={`
                  relative w-full h-32 rounded overflow-hidden 
                  border-2
                  ${
                    selectedWallpaper === wallpaper.id
                      ? "border-green-500"
                      : "border-transparent"
                  }
                `}
            >
              <img
                src={wallpaper.src}
                alt={wallpaper.name}
                className="absolute inset-0 w-full h-full object-cover"
              />
              {/* Optional: a dark overlay plus name in bottom-left corner */}
              <div className="absolute inset-0 bg-black bg-opacity-30 flex items-end p-2">
                <span className="text-xs">{wallpaper.name}</span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

// Another Submenu Screen Example
const AboutDeviceScreen = ({ onBack }) => {
  return (
    <div className="h-full w-full flex flex-col bg-black text-white">
      {/* Header */}
      <div className="flex items-center px-4 py-3 border-b border-gray-800 bg-gray-900">
        <button onClick={onBack} className="mr-4">
          <AiOutlineLeft className="text-2xl" />
        </button>
        <h2 className="text-xl font-bold">About Device</h2>
      </div>

      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto">
        <div className="px-4 mt-4">
          <div className="bg-gray-900 rounded-lg overflow-hidden mb-4">
            <div className="flex flex-col p-3 space-y-1 border-b border-gray-800">
              <span className="text-sm">Name</span>
              <span className="text-xs text-gray-400">Brandon’s iPhone</span>
            </div>
            <div className="flex flex-col p-3 space-y-1 border-b border-gray-800">
              <span className="text-sm">Software Version</span>
              <span className="text-xs text-gray-400">iOS 17.0</span>
            </div>
            <div className="flex flex-col p-3 space-y-1 border-b border-gray-800">
              <span className="text-sm">Model Number</span>
              <span className="text-xs text-gray-400">XYZ123</span>
            </div>
            <div className="flex flex-col p-3 space-y-1">
              <span className="text-sm">Tech Stack</span>
              <span className="text-xs text-gray-400">
                React, TailwindCSS, NodeJS, Firebase.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileSettings = ({ selectedWallpaper, setSelectedWallpaper }) => {
  // Track which screen we're on
  const [activeScreen, setActiveScreen] = useState("home");

  // Example state for toggles
  const [airplaneMode, setAirplaneMode] = useState(false);
  const [wifi, setWifi] = useState(true);
  const [bluetooth, setBluetooth] = useState(true);

  // Return different screens based on activeScreen
  const renderScreen = () => {
    switch (activeScreen) {
      case "home":
        return (
          <div className="flex-1 overflow-y-auto pb-4">
            {/* Profile area */}
            <div className="p-4">
              <div className="bg-gray-900 rounded-lg p-4 flex items-center space-x-3">
                <img
                  src="image0.jpg"
                  alt="profile"
                  className="w-14 h-14 rounded-full"
                />
                <div>
                  <div className="font-semibold text-base">Brandon Martin</div>
                  <div className="text-gray-400 text-sm">
                    Apple ID, iCloud, Media &amp; Purchases
                  </div>
                </div>
              </div>
            </div>

            {/* Airplane Mode, Wi-Fi, Bluetooth, etc. */}
            <div className="px-4">
              <div className="bg-gray-900 rounded-lg overflow-hidden mb-4">
                {/* Airplane Mode */}
                <div className="flex items-center justify-between p-3 border-b border-gray-800">
                  <div className="flex items-center space-x-3">
                    <BsAirplane className="text-yellow-400" />
                    <span className="text-sm">Airplane Mode</span>
                  </div>
                  <Toggle
                    enabled={airplaneMode}
                    onChange={() => setAirplaneMode(!airplaneMode)}
                  />
                </div>

                {/* Wi-Fi */}
                <button className="flex items-center justify-between w-full p-3 border-b border-gray-800 hover:bg-gray-800">
                  <div className="flex items-center space-x-3">
                    <AiOutlineWifi className="text-blue-500" />
                    <div>
                      <div className="text-sm text-left">Wi-Fi</div>
                      <div className="text-xs text-gray-400 text-left">
                        {wifi ? "YourNetwork" : "Off"}
                      </div>
                    </div>
                  </div>
                  <Toggle enabled={wifi} onChange={() => setWifi(!wifi)} />
                </button>

                {/* Bluetooth */}
                <button className="flex items-center justify-between w-full p-3 border-b border-gray-800 hover:bg-gray-800">
                  <div className="flex items-center space-x-3">
                    <BiBluetooth className="text-blue-400" />
                    <div>
                      <div className="text-sm text-left">Bluetooth</div>
                      <div className="text-xs text-gray-400 text-left">
                        {bluetooth ? "On" : "Off"}
                      </div>
                    </div>
                  </div>
                  <Toggle
                    enabled={bluetooth}
                    onChange={() => setBluetooth(!bluetooth)}
                  />
                </button>

                {/* Cellular */}
                <button className="flex items-center justify-between w-full p-3 border-b border-gray-800 hover:bg-gray-800">
                  <div className="flex items-center space-x-3">
                    <MdCellTower className="text-green-500" />
                    <span className="text-sm">Cellular</span>
                  </div>
                  <AiOutlineRight />
                </button>

                {/* Battery */}
                <button className="flex items-center justify-between w-full p-3 hover:bg-gray-800">
                  <div className="flex items-center space-x-3">
                    <IoBatteryFull className="text-green-500" />
                    <span className="text-sm">Battery</span>
                  </div>
                  <AiOutlineRight />
                </button>
              </div>
            </div>

            {/* Another Section */}
            <div className="px-4">
              <div className="bg-gray-900 rounded-lg overflow-hidden mb-4">
                <button
                  className="flex items-center justify-between w-full p-3 border-b border-gray-800 hover:bg-gray-800"
                  onClick={() => setActiveScreen("general")}
                >
                  <span className="text-sm">General</span>
                  <AiOutlineRight />
                </button>
                <button
                  className="flex items-center justify-between w-full p-3 border-b border-gray-800 hover:bg-gray-800"
                  onClick={() => setActiveScreen("wallpapers")}
                >
                  <span className="text-sm">Wallpapers</span>
                  <AiOutlineRight />
                </button>
                <button
                  className="flex items-center justify-between w-full p-3 hover:bg-gray-800"
                  onClick={() => setActiveScreen("aboutDevice")}
                >
                  <span className="text-sm">About Device</span>
                  <AiOutlineRight />
                </button>
              </div>
            </div>
          </div>
        );

      case "general":
        // Render the General submenu screen
        return <GeneralScreen onBack={() => setActiveScreen("home")} />;

      case "wallpapers":
        // Render the Wallpapers submenu screen
        return (
          <WallpapersScreen
            setSelectedWallpaper={setSelectedWallpaper}
            selectedWallpaper={selectedWallpaper}
            onBack={() => setActiveScreen("home")}
          />
        );

      case "aboutDevice":
        // Render the About Device submenu screen
        return <AboutDeviceScreen onBack={() => setActiveScreen("home")} />;

      default:
        return null;
    }
  };

  return (
    <div className="h-full w-full flex flex-col bg-black text-white overflow-hidden">
      {renderScreen()}
    </div>
  );
};

export default MobileSettings;
