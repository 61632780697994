import React, { useState } from "react";
import Lottie from "react-lottie";
import dayjs from "dayjs";
import helpAnimation from "../helper3.json";
import appNotFound from "../Apps/404.json";
import MobileApp from "./MobileApp";
import AppNotFound from "./AppNotFound";
import MobileChatbot from "./Apps/MobileChatbot";
import MobileMessenger from "./Apps/MobileMessenger";
import MobileYoutube from "./Apps/MobileYoutube";
import MobileBrowser from "./Apps/MobileBrowser";
import MobileImageGenerator from "./Apps/MobileImageGenerator";
import MobileAboutMe from "./Apps/MobileAboutMe";
import MobileProjects from "./Apps/MobileProjects";
import MobileSettings from "./Apps/MobileSettings";
import RaftLife from "./Apps/RaftLife";
import FruitNinja from "./Apps/ZombieIdle";
import Zombie from "./Apps/ZombieIdle";
import BallAdventure from "./Apps/BallAdventure";
import BubbleBlast from "./Apps/BubbleBlast";
import TurboRace from "./Apps/TurboRace";
import MobileWeather from "./Apps/MobileWeather";
import Phone from "./Apps/Phone";
import MobileMessages from "./Apps/MobileMessages";

const MobileSystem = () => {
  const [selectedWallpaper, setSelectedWallpaper] = useState(1);

  return (
    <div
      className="text-white w-full h-[100svh] relative overflow-hidden"
      style={{
        backgroundImage: `url(/wallpaper${selectedWallpaper}.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Top status bar */}
      <div className="flex items-center justify-between px-4 h-8 mt-2">
        <div className="text-xs">{dayjs().format("h:mm A")}</div>
        <div className="flex items-center space-x-1 text-xs">
          <span>Wi-Fi</span>
          <span>Battery</span>
        </div>
      </div>

      {/* Dynamic Island / Camera cutout */}
      <div className="absolute top-3 left-1/2 -translate-x-1/2 bg-[#191919] rounded-full w-24 h-6" />

      {/* Main content container */}
      <div className="pt-4 px-4 h-[calc(100%-70px)] box-border overflow-hidden flex flex-col justify-between">
        {/* Top widgets */}
        <div className="flex flex-col space-y-2 mb-4">
          <div className="bg-neutral-900 p-4 rounded-xl flex items-center shadow-md">
            <div className="flex items-center justify-center pr-2">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: helpAnimation,
                }}
                height={80}
                width={80}
              />
            </div>
            <div className="text-xs pl-2">
              Welcome to my portfolio! I'm Brandon, a full-stack JavaScript
              developer. If you don't know where to start, I recommend the
              "About Me" and "Projects" applications. Thank you and enjoy!
            </div>
          </div>
        </div>

        {/*
          HORIZONTAL SWIPE CONTAINER:
          - 2 pages wide => w-[200%].
          - Each page is w-full (or min-w-full) + flex-shrink-0 so we don't cut off icons.
          - snap-x snap-mandatory => each page snaps in place.
        */}
        <div className="flex-grow relative overflow-hidden">
          <div className="flex w-[100%] h-full overflow-x-auto snap-x snap-mandatory">
            {/* Page 1: Your entire original grid, exactly as before */}
            <div className="w-full snap-center flex-shrink-0 overflow-y-auto">
              <div className="grid grid-cols-4 gap-4 content-start pb-4">
                <MobileApp title="About Me" icon="portfolio.png">
                  <MobileAboutMe />
                </MobileApp>

                <MobileApp title="YouTube" icon="youtube.png">
                  <MobileYoutube />
                </MobileApp>

                <MobileApp title="Messenger" icon="gmail.png">
                  <MobileMessenger />
                </MobileApp>

                <MobileApp title="Browser" icon="browserIcon.png">
                  <MobileBrowser />
                </MobileApp>

                <MobileApp title="AI Chatbot" icon="chatbot.png">
                  <MobileChatbot />
                </MobileApp>

                <MobileApp title="AI Image" icon="aiimage.png">
                  <MobileImageGenerator />
                </MobileApp>

                <MobileApp
                  title="Settings"
                  icon="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Settings_%28iOS%29.png/768px-Settings_%28iOS%29.png"
                >
                  <MobileSettings
                    selectedWallpaper={selectedWallpaper}
                    setSelectedWallpaper={setSelectedWallpaper}
                  />
                </MobileApp>

                <MobileApp
                  title="Weather"
                  icon="https://i.pinimg.com/originals/77/0b/80/770b805d5c99c7931366c2e84e88f251.png"
                >
                  <MobileWeather />
                </MobileApp>

                <MobileApp title="Projects" icon="ui-design.png">
                  <MobileProjects />
                </MobileApp>
              </div>
            </div>

            {/* Page 2: Single icon in the center (example: Another settings icon) */}
            <div className="w-full snap-center flex-shrink-0 overflow-y-auto">
              <div className="grid grid-cols-4 gap-4 content-start pb-4">
                <MobileApp
                  title="Zombie Tower"
                  icon="https://img.gamedistribution.com/39d8fb0fc5784ff5a6445e4f84645f6e-512x384.jpg"
                >
                  <Zombie />
                </MobileApp>
                <MobileApp
                  title="Ball Adventure"
                  icon="https://img.gamedistribution.com/a58f4f4fa6c34f21ad728e90c53ee7d4-512x384.jpg"
                >
                  <BallAdventure />
                </MobileApp>
                <MobileApp
                  title="Bubble Blast"
                  icon="https://img.gamedistribution.com/48583ddbe6f74c5f8a16653b555914e3-512x384.jpg"
                >
                  <BubbleBlast />
                </MobileApp>
                <MobileApp
                  title="Raft Life"
                  icon="https://gamaverse.com/c/i/i/raft-life.jpg"
                >
                  <RaftLife />
                </MobileApp>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom dock */}
        <div className="flex items-center justify-center">
          <div className="bg-[#191919] w-[90%] h-[80px] rounded-2xl flex items-center justify-evenly px-4">
            {/* Dock icons replaced with MobileApps */}
            <MobileApp
              hideTitle={true}
              title="Phone"
              icon="https://upload.wikimedia.org/wikipedia/commons/7/7e/Phone_iOS.png"
            >
              <Phone />
            </MobileApp>

            <MobileApp
              hideTitle={true}
              title="Messages"
              icon="https://cdn0.iconfinder.com/data/icons/apple-apps/100/Apple_Messages-512.png"
            >
              <MobileMessages />
            </MobileApp>

            <MobileApp
              hideTitle={true}
              title="Music"
              icon="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Apple_Music_icon.svg/2048px-Apple_Music_icon.svg.png"
            >
              <AppNotFound />
            </MobileApp>

            <MobileApp
              hideTitle={true}
              title="Photos"
              icon="https://cdn0.iconfinder.com/data/icons/apple-apps/100/Apple_Photos-512.png"
            >
              <AppNotFound />
            </MobileApp>

            <MobileApp
              hideTitle={true}
              title="Camera"
              icon="https://cdn0.iconfinder.com/data/icons/apple-apps/100/Apple_Camera-512.png"
            >
              <AppNotFound />
            </MobileApp>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSystem;
