import React from "react";
import { FaBeer } from "react-icons/fa";
import Draggable from "react-draggable";

const Icon = ({
  image,
  name,
  location,
  handleOpenApplication,
  applicationData,
}) => {
  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleDoubleClick = () => {
    handleOpenApplication(applicationData);
  };

  return (
    <Draggable defaultPosition={location}>
      <div className="Icon" onDoubleClick={handleDoubleClick}>
        <img
          onMouseDown={handleMouseDown}
          style={{ width: 50, height: 50 }}
          src={image}
          alt={name}
        />
        <p style={{ color: "white" }}>{name}</p>
      </div>
    </Draggable>
  );
};

export default Icon;
