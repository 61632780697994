import React, { useState, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoReload } from "react-icons/io5";

const MobileBrowser = ({ setUrlbar }) => {
  const [url, setUrl] = useState("https://en.wikipedia.org/wiki/JavaScript");
  const [viewedUrl, setViewedUrl] = useState(
    "https://en.wikipedia.org/wiki/JavaScript"
  );
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const iframeRef = useRef(null);

  const handleNavigate = () => {
    // Create a new array up to the current index (handles forward nav overwrites)
    const newHistory = history.slice(0, currentIndex + 1);
    newHistory.push(url);
    setHistory(newHistory);

    setViewedUrl(url);
    setCurrentIndex(newHistory.length - 1);
  };

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNavigate();
    }
  };

  const goBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setUrl(history[currentIndex - 1]);
      setViewedUrl(history[currentIndex - 1]);
    }
  };

  const goForward = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setUrl(history[currentIndex + 1]);
      setViewedUrl(history[currentIndex + 1]);
    }
  };

  const reload = () => {
    if (iframeRef.current) {
      iframeRef.current.src = viewedUrl;
    }
  };

  // Helper to quickly set both "url" and "viewedUrl"
  const setSite = (newSite) => {
    setUrl(newSite);
    setViewedUrl(newSite);
  };

  return (
    <div className="flex flex-col w-full h-full bg-black">
      {/* Top address bar */}
      <div className="p-2 flex items-center space-x-2 bg-gray-900 text-white">
        <button
          onClick={goBack}
          disabled={currentIndex <= 0}
          className="disabled:opacity-50"
        >
          <FaArrowLeft />
        </button>
        <button
          onClick={goForward}
          disabled={currentIndex >= history.length - 1}
          className="disabled:opacity-50"
        >
          <FaArrowRight />
        </button>
        <button onClick={reload}>
          <IoReload />
        </button>

        <input
          type="text"
          value={url}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter URL here"
          onFocus={() => setUrlbar?.(true)}
          onBlur={() => setUrlbar?.(false)}
          className="flex-grow ml-2 px-2 py-1 bg-gray-800 text-white 
                     rounded focus:outline-none"
        />
      </div>

      {/* Quick bookmarks row */}
      <div className="flex items-center p-2 space-x-4 bg-gray-800 text-white">
        <img
          onClick={() => setSite("https://www.hackthissite.org/")}
          src="hts.png"
          alt="HackThisSite"
          className="h-5 cursor-pointer"
        />
        <img
          onClick={() => setSite("https://www.google.com/webhp?igu=1")}
          src="google.png"
          alt="Google"
          className="h-6 cursor-pointer"
        />
        <img
          onClick={() => setSite("https://en.wikipedia.org/wiki/JavaScript")}
          src="wikipedia.png"
          alt="Wikipedia"
          className="h-5 cursor-pointer"
        />
        <img
          onClick={() => setSite("https://reactjs.org/")}
          src="react.png"
          alt="React"
          className="h-5 cursor-pointer"
        />
        <img
          onClick={() => setSite("https://nextjs.org/")}
          src="next.png"
          alt="NextJS"
          className="h-5 cursor-pointer"
        />
      </div>

      {/* Iframe container */}
      <div className="flex-grow">
        <iframe
          ref={iframeRef}
          src={viewedUrl}
          className="w-full h-full border-0"
          title="Mobile Browser"
        />
      </div>
    </div>
  );
};

export default MobileBrowser;
