import React from "react";
import Lottie from "react-lottie";
import appNotFound from "../Apps/404.json";

const AppNotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-12 p-4">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: appNotFound,
        }}
        height={120}
        width={120}
      />
      <div className="text-center mt-6">
        This app is still under development! I am still building out the mobile
        version of my portfolio and have not gotten to completing this app yet.
      </div>
    </div>
  );
};

export default AppNotFound;
