import React, { useState, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoReload } from "react-icons/io5";

const Browser = ({ setUrlbar }) => {
  const [url, setUrl] = useState("https://en.wikipedia.org/wiki/JavaScript");
  const [viewedUrl, setViewedUrl] = useState(
    "https://en.wikipedia.org/wiki/JavaScript"
  );
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const iframeRef = useRef(null);

  const handleNavigate = () => {
    const newHistory = history.slice(0, currentIndex + 1); // Clone and truncate history if needed
    newHistory.push(url);
    setHistory(newHistory);
    setViewedUrl(url);
    setCurrentIndex(newHistory.length - 1);
  };

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNavigate();
    }
  };

  const goBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setUrl(history[currentIndex - 1]);
      setViewedUrl(history[currentIndex - 1]);
    }
  };

  const goForward = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setUrl(history[currentIndex + 1]);
      setViewedUrl(history[currentIndex + 1]);
    }
  };

  const reload = () => {
    iframeRef.current.src = viewedUrl;
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
      }}
    >
      <div style={{ padding: "10px" }}>
        <button
          style={{
            color: "white",
          }}
          onClick={goBack}
          disabled={currentIndex <= 0}
        >
          <FaArrowLeft />
        </button>
        <button
          style={{ marginLeft: "6px", color: "white" }}
          onClick={goForward}
          disabled={currentIndex >= history.length - 1}
        >
          <FaArrowRight />
        </button>
        <button style={{ marginLeft: "6px", color: "white" }} onClick={reload}>
          <IoReload />
        </button>
        <input
          type="text"
          value={url}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter URL here"
          onFocus={() => setUrlbar(true)}
          onBlur={() => setUrlbar(false)}
          style={{
            width: "88%",
            marginLeft: "10px",
            color: "white",
            paddingLeft: "5px",
          }}
        />
      </div>

      <div
        style={{
          paddingLeft: "4px",
          height: 24,
          display: "flex",
          alignItems: "center",
          backgroundColor: "black",
          marginTop: "-3px",
          paddingBottom: "6px",
        }}
      >
        <div style={{ marginLeft: 4 }}>
          <img
            onClick={() => {
              setUrl("https://www.hackthissite.org/");
              setViewedUrl("https://www.hackthissite.org/");
            }}
            src="hts.png"
            alt="HackThisSite"
            style={{ height: "20px", cursor: "pointer" }}
          />
        </div>
        <div style={{ marginLeft: 2 }}>
          <img
            onClick={() => {
              setUrl("https://www.google.com/webhp?igu=1");
              setViewedUrl("https://www.google.com/webhp?igu=1");
            }}
            src="google.png"
            alt="Google"
            style={{ height: "24px", cursor: "pointer" }}
          />
        </div>
        <div style={{ marginLeft: 4 }}>
          <img
            onClick={() => {
              setUrl("https://en.wikipedia.org/wiki/JavaScript");
              setViewedUrl("https://en.wikipedia.org/wiki/JavaScript");
            }}
            src="wikipedia.png"
            alt="Wikipedia"
            style={{ height: "18px", cursor: "pointer" }}
          />
        </div>
        <div style={{ marginLeft: 6 }}>
          <img
            onClick={() => {
              setUrl("https://reactjs.org/");
              setViewedUrl("https://reactjs.org/");
            }}
            src="react.png"
            alt="React"
            style={{ height: "15px", cursor: "pointer" }}
          />
        </div>
        <div style={{ marginLeft: 6 }}>
          <img
            onClick={() => {
              setUrl("https://nextjs.org/");
              setViewedUrl("https://nextjs.org/");
            }}
            src="next.png"
            alt="NextJS"
            style={{ height: "15px", cursor: "pointer" }}
          />
        </div>
      </div>
      <iframe
        src={viewedUrl || "https://en.wikipedia.org/wiki/JavaScript"}
        ref={iframeRef}
        style={{ width: "100%", height: "calc(100% - 50px)", border: "none" }}
        title="Browser"
      />
    </div>
  );
};

export default Browser;
