import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";

const MobileChatbot = () => {
  const [messages, setMessages] = useState([
    {
      content: "Hiya! I am Jared, the AI chatbot. Ask me anything!",
      role: "assistant",
    },
  ]);
  const [inputText, setInputText] = useState("");
  const messagesEndRef = useRef(null);

  const handleSendMessage = async (event) => {
    // Handle 'Enter' press without SHIFT
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await sendMessage();
    }
    // Handle click
    else if (event.type === "click") {
      await sendMessage();
    }
  };

  const sendMessage = async () => {
    // Validate user input
    if (inputText.trim().length === 0) {
      alert("Please enter a description.");
      return;
    }

    // Add user's message to the chat
    setMessages((currentMessages) => [
      ...currentMessages,
      { content: inputText, role: "user" },
    ]);

    // Keep local copy to pass into fetch
    const userInput = inputText;
    setInputText("");

    // Make your API request
    const talkData = await fetch(
      "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/talkToBot",
      {
        method: "POST",
        body: JSON.stringify({
          messages: [
            ...messages,
            {
              role: "user",
              content: userInput,
            },
          ],
        }),
      }
    );

    const response = await talkData.json();

    // Add the assistant's reply
    setMessages((currentMessages) => [
      ...currentMessages,
      { content: response.chatResponse, role: "assistant" },
    ]);
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex flex-col w-full h-full bg-[#444654] text-white">
      {/* Chat messages container */}
      <div className="flex-1 w-full overflow-y-auto px-2 py-2">
        {messages.map((message, index) => (
          <div
            key={index}
            className="text-left bg-[#444654] w-full min-h-[90px] flex items-center"
          >
            {/* Avatar bubble */}
            <div
              className={`min-h-[50px] min-w-[50px] flex justify-center items-center ml-5 text-white ${
                message.role === "user" ? "bg-[#5D6AC0]" : "bg-[#19C37D]"
              }`}
            >
              {message.role === "user" ? "U" : "J"}
            </div>
            {/* Message text */}
            <div className="ml-5">{message.content}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input row */}
      <div className="w-full flex items-center p-2">
        <textarea
          className="flex-grow h-10 bg-[#40414F] rounded-md outline-none resize-none text-white p-2"
          placeholder="Enter message here..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleSendMessage}
        />
        <div
          onClick={handleSendMessage}
          className="ml-2 flex items-center justify-center w-10 h-10 bg-[#40414F] text-white border border-white rounded-md cursor-pointer"
        >
          <AiOutlineSend />
        </div>
      </div>
    </div>
  );
};

export default MobileChatbot;
