import React, { useEffect, useRef } from "react";
import Desktop from "./Desktop";
import Taskbar from "./Taskbar";
import StartMenu from "./StartMenu";
import DesktopApplication from "./DesktopApplication";
import WAVES from "vanta/dist/vanta.waves.min";
import * as THREE from "three";
import Lottie from "react-lottie";
import helpAnimation from "./helper3.json";
import { IoMdCloseCircle } from "react-icons/io";
import { isMobile } from "react-device-detect";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import MobileSystem from "./Mobile/MobileSystem";

const firebaseConfig = {
  apiKey: "AIzaSyAq-X-xzhj1vVOFDtgUV9XOYFTK14ghHcM",
  authDomain: "brandonportfolio-42f62.firebaseapp.com",
  projectId: "brandonportfolio-42f62",
  storageBucket: "brandonportfolio-42f62.appspot.com",
  messagingSenderId: "141440955298",
  appId: "1:141440955298:web:045ec64d6efe02fd0ff8fb",
};

firebase.initializeApp(firebaseConfig);

const App = () => {
  const [startMenuActive, setStartMenuActive] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [runningApplications, setRunningApplications] = React.useState([]);
  const [displayHelper, setDisplayHelper] = React.useState(true);
  const [positions, setPositions] = React.useState({});
  const [topApp, setTopApp] = React.useState(null);
  const [appData, setAppData] = React.useState();
  const vantaRef = useRef(null);
  const presetColors = [
    0x303d61, // Existing dark color
    0x1b263b, // Dark Blue
    0x4b0082, // Indigo (Dark Purple)
    0x013220, // Very Dark Green
    0x2f4f4f, // Dark Slate Gray
  ];

  useEffect(() => {
    let vantaEffect;
    let animationFrameId;
    let colorIndex = 0;
    let progress = 0;
    const duration = 200;
    const speed = 0.0015;
    const maxBrightness = 0.5;

    const interpolateColor = (color1, color2, factor) => {
      const r1 = (color1 >> 16) & 0xff,
        g1 = (color1 >> 8) & 0xff,
        b1 = color1 & 0xff;
      const r2 = (color2 >> 16) & 0xff,
        g2 = (color2 >> 8) & 0xff,
        b2 = color2 & 0xff;

      let r = Math.round(r1 + factor * (r2 - r1));
      let g = Math.round(g1 + factor * (g2 - g1));
      let b = Math.round(b1 + factor * (b2 - b1));

      return (r << 16) + (g << 8) + b;
    };

    if (!vantaEffect && vantaRef.current) {
      vantaEffect = WAVES({
        el: vantaRef.current,
        THREE: THREE,
        mouseControls: false,
        color: presetColors[colorIndex],
        waveSpeed: 0.5,
      });
    }

    const updateColor = () => {
      progress += speed;

      if (progress >= 1) {
        progress = 0;
        colorIndex = (colorIndex + 1) % presetColors.length;
      }

      const nextColorIndex = (colorIndex + 1) % presetColors.length;
      const interpolatedColor = interpolateColor(
        presetColors[colorIndex],
        presetColors[nextColorIndex],
        progress
      );

      if (vantaEffect) {
        vantaEffect.setOptions({ color: interpolatedColor });
      }

      animationFrameId = requestAnimationFrame(updateColor);
    };

    animationFrameId = requestAnimationFrame(updateColor);

    return () => {
      if (vantaEffect) vantaEffect.destroy();
      cancelAnimationFrame(animationFrameId);
    };
  }, [vantaRef.current]);

  const handleOpenApplication = (application, presetData) => {
    setAppData(presetData);
    setTopApp(application.id);
    setStartMenuActive(false);
    setRunningApplications((prevApplications) => {
      const isRunning = prevApplications.some(
        (app) => app.id === application.id
      );
      if (!isRunning) {
        setPositions((prevPositions) => ({
          ...prevPositions,
          [application.id]: { x: 200, y: 50 },
        }));
        return [...prevApplications, { ...application, isActive: true }];
      }
      return prevApplications.map((app) =>
        app.id === application.id ? { ...app, isActive: true } : app
      );
    });
  };

  const handleFocusApplication = (id) => {
    // Only set this application active, do not change the state of others
    setRunningApplications((prevApplications) =>
      prevApplications.map((app) =>
        app.id === id ? { ...app, isActive: true, minimize: false } : app
      )
    );
  };

  const handleMinimizeApplication = (id) => {
    setRunningApplications((prevApplications) =>
      prevApplications.map((app) =>
        app.id === id ? { ...app, minimize: true } : app
      )
    );
  };

  const handleCloseApplication = (id) => {
    setRunningApplications((prevApplications) =>
      prevApplications.filter((app) => app.id !== id)
    );
  };

  const updatePosition = (id, newPosition) => {
    setPositions((prevPositions) => ({
      ...prevPositions,
      [id]: newPosition,
    }));
  };

  return isMobile ? (
    <MobileSystem />
  ) : (
    <div style={{ position: "relative" }} className="App">
      <div
        ref={vantaRef}
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
        }}
      />
      {displayHelper && (
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            display: "flex",
            alignItems: "center", // transparent grey background
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            justifyContent: "flex-start",
            padding: 10,
            width: 600,
            maxHeight: 120,
            color: "white",
            borderRadius: 5,
          }}
        >
          <div style={{ position: "absolute", top: 10, right: 10, zIndex: 10 }}>
            <IoMdCloseCircle
              onClick={() => setDisplayHelper(false)}
              style={{ marginRight: 10, cursor: "pointer" }}
            />
          </div>
          <div style={{ width: "80%" }}>
            Welcome to my portfolio! I'm Brandon, a full-stack javascript
            developer. Feel free to explore the applications on this desktop. If
            you don't know where to start, I recommend the "About Me" and
            "Projects" applications. Thank you and enjoy!
          </div>
          <div style={{ position: "absolute", right: 14, top: -3 }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: helpAnimation,
              }}
              height={130}
              width={130}
            />
          </div>
        </div>
      )}
      <Desktop
        setStartMenuActive={setStartMenuActive}
        handleOpenApplication={handleOpenApplication}
      />
      {runningApplications.map((application, index) => (
        <DesktopApplication
          handleMinimizeApplication={handleMinimizeApplication}
          handleCloseApplication={handleCloseApplication}
          key={application.id}
          id={application.id}
          appImage={application.image}
          appName={application.name}
          value={application.value}
          minimize={application.minimize}
          position={positions[application.id]}
          updatePosition={updatePosition}
          index={index}
          setTopApp={setTopApp}
          topApp={topApp}
          appData={appData}
        />
      ))}

      <StartMenu
        handleOpenApplication={handleOpenApplication}
        startMenuActive={startMenuActive}
      />
      <Taskbar
        runningApplications={runningApplications}
        startMenuActive={startMenuActive}
        setStartMenuActive={setStartMenuActive}
        setRunningApplications={setRunningApplications}
        handleFocusApplication={handleFocusApplication}
      />
    </div>
  );
};

export default App;
