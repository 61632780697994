import React, { useEffect, useState } from "react";

const policeScannerImages = ["psp1.png", "psp2.png", "psp3.png"];

const MobileProjects = () => {
  const [imageToDisplay, setImageToDisplay] = useState(policeScannerImages[0]);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (activeTab === 1) {
      let index = 0;
      const interval = setInterval(() => {
        index = (index + 1) % policeScannerImages.length;
        setImageToDisplay(policeScannerImages[index]);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [activeTab]);

  return (
    <div className="w-full h-full flex flex-col bg-gray-900 text-white">
      {/* Tabs */}
      <div className="flex justify-center gap-4 p-4 bg-gray-800 border-b border-gray-700">
        <button
          onClick={() => setActiveTab(1)}
          className={`px-4 py-2 text-sm font-medium rounded-lg shadow-md transition-all
            ${
              activeTab === 1
                ? "bg-blue-500 text-white"
                : "bg-gray-700 text-gray-300 hover:bg-gray-600"
            }
          `}
        >
          Police Scanner Pro
        </button>
        <button
          onClick={() => setActiveTab(2)}
          className={`px-4 py-2 text-sm font-medium rounded-lg shadow-md transition-all
            ${
              activeTab === 2
                ? "bg-blue-500 text-white"
                : "bg-gray-700 text-gray-300 hover:bg-gray-600"
            }
          `}
        >
          Tropics Tracker
        </button>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-y-auto p-4">
        {activeTab === 1 && (
          <div className="flex flex-col items-center gap-6">
            {/* Image Carousel */}
            <div className="w-full max-w-md">
              <img
                src={imageToDisplay}
                alt="Police Scanner Pro"
                className="w-full h-auto rounded-lg shadow-lg border border-gray-700"
              />
            </div>

            {/* Project Details */}
            <div className="w-full max-w-md bg-gray-800 p-4 rounded-lg shadow-lg">
              <h2 className="text-lg font-semibold mb-3">
                Police Scanner Pro - USA
              </h2>
              <p className="text-sm leading-6 text-gray-300 mb-3">
                The most advanced police scanner app on iOS! Access live feeds,
                engage in state-wide chats, and enjoy an ad-free experience.
              </p>

              <div className="text-sm leading-6 text-gray-300 mb-3">
                <p>
                  Welcome to the most advanced police scanner app on iOS! Our
                  app puts thousands of live police, fire, and EMS scanner feeds
                  right at your fingertips, ensuring you stay informed and ahead
                  of the curve.
                </p>
                <p>
                  From small towns to major metropolitan areas, our extensive
                  database covers scanner feeds across the United States.
                  Constantly updated, we ensure you're getting the latest and
                  most accurate information.
                </p>
              </div>

              <div className="mb-3">
                <strong className="block mb-1">Technologies Used:</strong>
                <p className="text-sm text-gray-300">
                  JavaScript, React Native, Node.js, Firebase, Google Maps API
                </p>
              </div>

              <a
                href="https://apps.apple.com/us/app/police-scanner-pro-usa/id6451319565"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline text-sm"
              >
                View on the Apple App Store
              </a>
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div className="flex flex-col items-center gap-6">
            {/* Image Section */}
            <div className="flex gap-4 overflow-y-scroll">
              <img
                src="tt1.png"
                alt="Tropics Tracker 1"
                className="w-1/2 max-w-[200px] rounded-lg shadow-lg border border-gray-700"
              />
              <img
                src="tt2.png"
                alt="Tropics Tracker 2"
                className="w-1/2 max-w-[200px] rounded-lg shadow-lg border border-gray-700"
              />
            </div>

            {/* Project Details */}
            <div className="w-full max-w-md bg-gray-800 p-4 rounded-lg shadow-lg">
              <h2 className="text-lg font-semibold mb-3">Tropics Tracker</h2>
              <p className="text-sm leading-6 text-gray-300 mb-3">
                This is a project I built for fun because I enjoy tracking
                hurricanes. The site has a feature called{" "}
                <strong>StormWall</strong> that allows users to submit live
                video feeds during hurricanes, and everyone can watch and chat
                together.
              </p>
              <p className="text-sm leading-6 text-gray-300 mb-3">
                The site also features a storm tracker page as well as an
                advanced GFS radar with wind visualization and live lightning
                strike tracking.
              </p>

              <div className="mb-3">
                <strong className="block mb-1">Technologies Used:</strong>
                <p className="text-sm text-gray-300">
                  JavaScript, React, Next.js, Node.js, Firebase, Discord, Google
                  Maps API, Mapbox GL JS, TailwindCSS, Express
                </p>
              </div>

              <a
                href="https://tropicstracker.io"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline text-sm"
              >
                Visit Tropics Tracker
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileProjects;
