import React, { useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IoMdSend } from "react-icons/io";
import firebase from "firebase/compat/app";

const MobileMessenger = () => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  // Sign in anonymously if not already signed in
  const signInAnonymouslyHandler = async () => {
    try {
      if (auth.currentUser) return;
      const userCredential = await auth.signInAnonymously();
      setUser(userCredential.user.uid);
    } catch (error) {
      console.error("Error signing in anonymously:", error);
    }
  };

  // Fetch existing messages for this user
  const getMessages = async (uid) => {
    try {
      if (!uid) return;
      const doc = await db.collection("Messages").doc(uid).get();
      if (doc.exists) {
        setMessages(doc.data().messages);
      }
    } catch (error) {
      console.error("Error getting messages:", error);
    }
  };

  // Initialize on mount
  useEffect(() => {
    signInAnonymouslyHandler();

    const unsubscribe = auth.onAuthStateChanged((currUser) => {
      if (currUser) {
        setUser(currUser.uid);
        getMessages(currUser.uid);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessageHandler = async () => {
    try {
      const trimmedMessage = message.trim();
      if (trimmedMessage.length === 0) return;
      setMessage("");

      if (!user) return;

      // Add the user's message locally
      const messageObject = {
        message: trimmedMessage,
        timestamp: new Date().getTime(),
        from: "user",
      };
      const updatedMessages = [...messages, messageObject];
      setMessages(updatedMessages);

      // Call your cloud function
      const res = await fetch(
        "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/createDiscordChannel",
        {
          method: "POST",
          body: JSON.stringify({
            customerId: user,
            customerName: "Portfolio User",
            initialMessage: trimmedMessage,
          }),
        }
      );
      console.log("createDiscordChannel response status:", res.status);

      // If it's the first message, auto-reply
      if (messages.length === 0) {
        setTimeout(async () => {
          const autoReply = {
            message:
              "Hello! Thanks for messaging me. I'll get an alert for this message and will respond as soon as I can. Thank you!",
            timestamp: new Date().getTime(),
            from: "brandon",
          };

          const newArr = [...updatedMessages, autoReply];
          setMessages(newArr);

          // Update in Firestore
          await db
            .collection("Messages")
            .doc(user)
            .set(
              {
                messages: firebase.firestore.FieldValue.arrayUnion(autoReply),
              },
              { merge: true }
            );
        }, 3000);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div
      className="w-full h-full bg-black"
      // If you need to prevent clicks from closing your MobileApp, you can do:
      // onMouseDown={(e) => e.stopPropagation()}
    >
      {/* Header */}
      <div
        className="h-20 flex justify-start items-center border-b border-[#202020]
                   bg-cover bg-center relative"
        style={{
          backgroundImage: "url('pixelbg.gif')",
          backgroundPosition: "center 80%",
        }}
      >
        <div className="ml-2">
          <img
            src="image0.jpg"
            alt="Brandon Martin"
            className="w-14 h-14 rounded-full border-2 border-white"
          />
        </div>
        <div className="flex flex-col ml-2">
          <div
            className="text-white font-bold text-xl"
            style={{ textShadow: "2px 2px 2px #000000" }}
          >
            Brandon Martin
          </div>
          <div
            className="text-white font-bold text-sm"
            style={{ textShadow: "2px 2px 2px #000000" }}
          >
            Self-Taught Fullstack Engineer
          </div>
        </div>
      </div>

      {/* Messages List */}
      <div className="overflow-auto mt-2 h-auto">
        {messages.map((m, index) => (
          <div
            key={index}
            className={`flex px-2 py-2 items-start ${
              m.from === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`text-white p-2 rounded-md max-w-[70%] break-words ${
                m.from === "user" ? "bg-[#3B3B3B]" : "bg-[#1F57E7]"
              }`}
            >
              {m.message}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Row */}
      <div className="h-15 w-full p-2 flex items-center absolute bottom-4">
        <input
          className="w-[90%] h-10 rounded-md px-2 outline-none text-white bg-[#222] mr-2"
          placeholder="Enter message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessageHandler();
            }
          }}
        />
        <div
          onClick={sendMessageHandler}
          className="w-[10%] bg-[#3B3B3B] flex items-center justify-center rounded-md h-10 cursor-pointer"
        >
          <IoMdSend size={24} color="white" />
        </div>
      </div>
    </div>
  );
};

export default MobileMessenger;
