import React, { useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import Lottie from "react-lottie";
import animationData from "../../helper1.json";

const MobileImageGenerator = () => {
  const [inputText, setInputText] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");

  const generateImage = async (event) => {
    // Handle ENTER key press (without SHIFT)
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (inputText.trim().length === 0) {
        alert("Please enter a description.");
        return;
      }
      await fetchImage(inputText);
    }
    // Handle button click
    else if (event.type === "click") {
      if (inputText.trim().length === 0) {
        alert("Please enter a description.");
        return;
      }
      if (!loading) {
        await fetchImage(inputText);
      }
    }
  };

  const fetchImage = async (description) => {
    try {
      setLoading(true);
      setPrompt(description);
      setInputText("");

      const response = await fetch(
        "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/generateImage",
        {
          method: "POST",
          body: JSON.stringify({ prompt: description }),
        }
      );
      const data = await response.json();
      setImage(data.image);
      setLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-start w-full h-full bg-[#444654]">
      {/* Main display area (80% height) */}
      <div className="flex flex-col items-center justify-center w-full h-4/5 p-2">
        {loading && (
          <>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={300}
              width={300}
            />
            <h1 className="text-white mt-[-30px]">Generating Image...</h1>
          </>
        )}

        {!loading && image && (
          <>
            <img
              src={image}
              alt="Generated"
              className="w-full h-4/5 object-contain rounded-md"
            />
            <div className="text-white mt-2">{prompt}</div>
          </>
        )}
      </div>

      {/* Input row (absolute at bottom) */}
      <div className="absolute bottom-0 w-full h-12 flex items-center p-2 mb-2">
        <textarea
          value={inputText}
          disabled={loading}
          placeholder="Enter image description here..."
          onKeyDown={generateImage}
          onChange={(event) => setInputText(event.target.value)}
          className="w-full h-10 bg-[#40414F] rounded-md outline-none resize-none text-white p-2"
        />
        <div
          onClick={generateImage}
          className="ml-2 h-10 w-12 bg-[#40414F] text-white flex items-center justify-center border border-white rounded-md cursor-pointer"
        >
          <AiOutlineSend />
        </div>
      </div>
    </div>
  );
};

export default MobileImageGenerator;
