import React, { useState, useRef, useEffect } from "react";

const GLOBAL_CONTACTS = [
  {
    name: "Peter Griffin",
    photo: "peter.webp",
    callAudio: "peter.mp3",
  },
  {
    name: "Grandma Edna",
    photo: "edna.webp",
    callAudio: "edna.mp3",
  },
  {
    name: "Spongebob",
    photo: "spongebob.webp",
  },
  {
    name: "Eric Cartman",
    photo: "eric.webp",
    callAudio: "eric.mp3",
  },
];

const defaultThreads = [
  {
    id: 0,
    contact: GLOBAL_CONTACTS[0],
    messages: [
      { role: "user", content: "Hey Peter, what's new at the brewery?" },
      {
        role: "assistant",
        content: "Nothin’ much! Just lovin’ the Pawtucket Patriot.",
      },
      { role: "user", content: "Awesome! Save some for me." },
    ],
    lastUpdated: "9:40 AM",
  },
  {
    id: 1,
    contact: GLOBAL_CONTACTS[1],
    messages: [
      { role: "user", content: "Hi Grandma, how are you doing?" },
      { role: "assistant", content: "Oh dear, I baked cookies for you!" },
      { role: "user", content: "Yay! Send them over!" },
    ],
    lastUpdated: "8:25 AM",
  },
  {
    id: 2,
    contact: GLOBAL_CONTACTS[2],
    messages: [
      { role: "assistant", content: "I'm ready, I'm ready, I'm ready!" },
      { role: "user", content: "Haha, I love your energy!" },
    ],
    lastUpdated: "Yesterday",
  },
  {
    id: 3,
    contact: GLOBAL_CONTACTS[3],
    messages: [
      { role: "assistant", content: "Screw you guys, I’m going home!" },
      { role: "user", content: "Classic Cartman. Catch you later!" },
    ],
    lastUpdated: "Yesterday",
  },
];

export default function MobileMessages() {
  const [threads, setThreads] = useState(defaultThreads);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [threads, selectedThreadId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const openThread = (contact) => {
    const existingThread = threads.find(
      (th) => th.contact.name === contact.name
    );
    if (existingThread) {
      setSelectedThreadId(existingThread.id);
    } else {
      const newThread = {
        id: threads.length,
        contact,
        messages: [],
        lastUpdated: "Just now",
      };
      setThreads([...threads, newThread]);
      setSelectedThreadId(newThread.id);
    }
  };

  const sendMessage = async () => {
    if (messageInput.trim() === "" || selectedThreadId == null) return;

    const updatedThreads = [...threads];
    const threadIndex = updatedThreads.findIndex(
      (t) => t.id === selectedThreadId
    );
    if (threadIndex === -1) return;

    updatedThreads[threadIndex].messages.push({
      role: "user",
      content: messageInput,
    });
    updatedThreads[threadIndex].lastUpdated = "Just now";
    setThreads(updatedThreads);
    setMessageInput("");

    try {
      const talkData = await fetch(
        "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/talkToContact",
        {
          method: "POST",
          body: JSON.stringify({
            messages: updatedThreads[threadIndex].messages,
            contact: updatedThreads[threadIndex].contact.name,
          }),
        }
      );

      const response = await talkData.json();

      updatedThreads[threadIndex].messages.push({
        role: "assistant",
        content: response.chatResponse,
      });
      updatedThreads[threadIndex].lastUpdated = "Just now";
      setThreads([...updatedThreads]);
    } catch (error) {
      console.error("Error talking to the bot:", error);
    }
  };

  if (selectedThreadId == null) {
    return (
      <div className="h-full w-full bg-gray-900 text-white flex flex-col">
        {/* Contact Bubbles */}
        <div className="grid grid-cols-3 gap-4 p-6 mt-4">
          {GLOBAL_CONTACTS.map((contact) => (
            <div
              key={contact.name}
              className="flex flex-col items-center space-y-3"
            >
              <button
                onClick={() => openThread(contact)}
                className="w-16 h-16 rounded-full overflow-hidden border-2 border-gray-700 hover:border-blue-500 transition-all"
              >
                <img
                  src={contact.photo}
                  alt={contact.name}
                  className="object-cover h-full w-full"
                />
              </button>
              <p className="text-sm text-gray-400 text-center font-medium">
                {contact.name.split(" ")[0]}
              </p>
            </div>
          ))}
        </div>

        {/* Threads List */}
        <div className="flex-1 overflow-y-auto p-2">
          {threads.map((thread) => (
            <button
              key={thread.id}
              className="w-full flex items-center px-6 py-4 bg-gray-800 hover:bg-gray-700 rounded-lg mb-2 shadow-md transition-all"
              onClick={() => setSelectedThreadId(thread.id)}
            >
              <img
                src={thread.contact.photo}
                alt={thread.contact.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="ml-4 flex flex-col flex-1">
                <div className="font-semibold text-white text-left">
                  {thread.contact.name}
                </div>
                <div className="text-gray-400 text-sm text-left truncate">
                  {thread.messages[
                    thread.messages.length - 1
                  ]?.content?.substring(0, 30) + "..." || "No messages yet"}
                </div>
              </div>
              <div className="text-xs text-gray-400 ml-auto pl-2">
                {thread.lastUpdated}
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }

  const currentThread = threads.find((t) => t.id === selectedThreadId);
  if (!currentThread) return null;

  return (
    <div className="h-full w-full flex flex-col bg-gray-900 text-white">
      {/* Header */}
      <div className="flex justify-between items-center px-6 py-4 border-b border-gray-700 bg-gray-800">
        <button
          onClick={() => setSelectedThreadId(null)}
          className="text-blue-500 hover:text-blue-400"
        >
          Back
        </button>
        <span className="font-semibold text-lg text-white">
          {currentThread.contact.name}
        </span>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-6 space-y-4">
        {currentThread.messages.map((message, idx) => (
          <div
            key={idx}
            className={`flex ${
              message.role === "assistant" ? "justify-start" : "justify-end"
            }`}
          >
            <div
              className={`rounded-xl px-4 py-3 max-w-[75%] shadow-md text-sm font-medium ${
                message.role === "assistant"
                  ? "bg-gray-800 text-gray-200"
                  : "bg-blue-500 text-white"
              }`}
            >
              {message.content}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input */}
      <div className="border-t border-gray-700 bg-gray-800 p-4 flex items-center space-x-3">
        <input
          type="text"
          className="flex-1 px-4 py-2 bg-gray-700 border border-gray-600 rounded-full text-sm text-white placeholder-gray-500 focus:outline-none focus:ring focus:ring-blue-500"
          placeholder="iMessage"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <button
          onClick={sendMessage}
          className="w-10 h-10 flex items-center justify-center bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600 active:bg-blue-700 transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 12H3m18 0l-7-7m7 7l-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
